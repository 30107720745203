import jQuery from 'jquery'
var RegionesYcomunas = {

    "regiones": [{
            "NombreRegion": "PR13: Indicadores de Calidad y Servicio",
            "comunas": ["Continuidad del Servicio de Agua Potable", "Continuidad del Servicio de Recolección de Agua Servidas", "Presión de Servicio de Agua Potable", "Exactitud en el Cobro del Servicio","Atención y Respuestas a Reclamos y Emergencias"]
    },
        {
            "NombreRegion": "PR14: Sistema de calidad de Agua Potable",
            "comunas": ["Autocontrol Agua Potable"]
    },
        {
            "NombreRegion": "PR18: Captaciones AP",
            "comunas": ["Derecho de agua", "Uso derechos", "Producción máxima diaria", "Niveles y Gastos", "Servicios Abastecidos"]
    },
        {
            "NombreRegion": "PR19: Control de facturación  mensual",
            "comunas": ["Facturación Clientes"]
    },
        {
            "NombreRegion": "PR23: Control de plantas de tratamiento A.S",
            "comunas": ["Variables Operacionales", "Autocontrol", "Lodos", "Manejo de reclamos", "Olores"]
    },
        {
            "NombreRegion": "PR48: Plan de acción cortes reiterados",
            "comunas": ["Cortes Reiterados", "Acciones y seguimiento del plan de acción"]
    }]
}


jQuery(document).ready(function () {

    var iRegion = 0;
    var htmlRegion = '<option id="selected_value" value="sin-region">Seleccione PR</option><option value="sin-region">--</option>';
 

    jQuery.each(RegionesYcomunas.regiones, function () {
        htmlRegion = htmlRegion + '<option value="' + RegionesYcomunas.regiones[iRegion].NombreRegion + '">' + RegionesYcomunas.regiones[iRegion].NombreRegion + '</option>';
        iRegion++;
    });

    jQuery('#regiones').html(htmlRegion);


    jQuery('#regiones').change(function () {
        var iRegiones = 0;
        var valorRegion = jQuery(this).val();
        var htmlComuna = '';
        jQuery.each(RegionesYcomunas.regiones, function () {
            if (RegionesYcomunas.regiones[iRegiones].NombreRegion === valorRegion) {
                var iComunas = 0;
                jQuery.each(RegionesYcomunas.regiones[iRegiones].comunas, function () {
                    htmlComuna = htmlComuna + '<option value="' + RegionesYcomunas.regiones[iRegiones].comunas[iComunas] + '">' + RegionesYcomunas.regiones[iRegiones].comunas[iComunas] + '</option>';
                    iComunas++;
                });
            }
            iRegiones++;
        });
        jQuery('#comunas').html(htmlComuna);
    });
    jQuery('#comunas').change(function () {
        if (jQuery(this).val() === 'sin-region') {
            alert('seleccione PR');
        } else if (jQuery(this).val() === 'sin-comuna') {
            alert('selecciones Archivos');
        }
    });
    jQuery('#regiones').change(function () {
        if (jQuery(this).val() === 'sin-region') {
            alert('seleccione PR');
        }
    });

});