import  {graph_data_line_fixer, graph_create_series}  from '../Helpers/utils';
import axios from 'axios';


const ButtonPrediction = async (chart, tipo, nombre, fecha, filtro, data, modelo) => {
    console.log(chart, tipo, nombre, fecha, filtro, modelo);
    console.log(process.env.REACT_APP_API_PREDICTION);
    chart.showLoading('Loading data...'); // show the loading spinner with default options

    let graphDataFixed = []
    await axios.post(`${process.env.REACT_APP_API_PREDICTION}/predictor`, {'tipo': tipo, 'codigo': nombre, 'fecha': fecha, 'filtro': filtro, 'modelo': modelo })
        .then(async (response) => {
            console.log("CONECTADO");

            if (chart.series[2] === undefined) {
                console.log("Entre");
                let graph_styles = graph_create_series(filtro, modelo);
                chart.addSeries(graph_styles.serie, false);
                chart.addSeries(graph_styles.range, false);
                console.log("SALI");
                chart.redraw();
            };

            const to_graph_data = response.data;
            console.log(response.data);
            graphDataFixed = graph_data_line_fixer(to_graph_data);
            console.log('Predicción ' + filtro + ' (' + modelo + ')');
            chart.series[1].name = 'Predicción ' + filtro + ' (' + modelo + ')'; 

        })
        .catch((err) => {
            console.error('Fallo::')
            console.error(err);
        }).finally(() => {
            chart.hideLoading(); // hide the loading spinner
        });
        ;
    let data_series = graphDataFixed.arrayLine;
    let data_range = graphDataFixed.arrayRange;
    let data_range_first = [(data.at(-1)).at(0),(data.at(-1)).at(1) , (data.at(-1)).at(1)];
    data_series.unshift(data.at(-1)); /*Permite la continuidad de la información*/
    data_range.unshift(data_range_first);

    chart.series[1].update({
        data: data_series,
        name: 'Predicción ' + filtro + ' (' + modelo + ')'
    });
    chart.series[2].update({
        data: data_range
    });
    chart.redraw();
        chart.redraw();      
    
}



export default ButtonPrediction