import React from 'react'
import { useForm } from "react-hook-form";
import './login.css'
import Logo from '../../Assets/Images/aguas_patagonias.png'
import axios from 'axios';


const Login = () => {

  const {register,handleSubmit,formState: { errors }} = useForm();

  const onSubmit = (data) => {

    console.log(data)
    axios.post(`${process.env.REACT_APP_IP_ADDRESS}/login`,
        {'password': data.password, 'email': data.email }).then((response) => { 
          if(response.data.status === 'success'){
        localStorage.setItem('token', response.data.session)
        localStorage.setItem('username', response.data.username)
        window.location.reload()
        }else{
          alert('error al iniciar sesion')
        }}).catch((error) => { console.log(error) });


  }; 




  return (
    <React.Fragment>

      <div className="container">

        <div className="containerForm">
          <div className="text-center"><span style={{ color: '#D2D4D5' }} >Login</span></div>
          <div className="d-flex justify-content-center mt-3">

            <div className="formStyle">

              <div className="logo col-12">
                <div>
                  <h3 className="text-center">
                    <img src={Logo} className="logoLogin" alt="" />
                    <hr />
                  </h3>

                </div>
              </div>

              <form onSubmit={handleSubmit(onSubmit)}>
                {/* register your input into the hook by invoking the "register" function */}



                <div className="inputWithIcon">  <input className='inputStyle' type='email'
                  placeholder='Correo' {...register("email",
                    {
                      required: { value: true, message: "Correo es requerido" },
                      minLength: { value: 4, message: "Min Length" }
                    })} />

                  <i className="far fa-envelope" aria-hidden="true"></i>

                </div>

                {errors.email && <p className='errorForm'> {errors.email.message}</p>}

                <div className="inputWithIcon">    <input className='inputStyle' type='password'
                  placeholder='Clave' {...register("password", {
                    required: { value: true, message: "Clave es requerida" },
                    minLength: { value: 4, message: "min lengh 4" }
                  })} />

                  <i className="fas fa-unlock-alt" aria-hidden="true"></i>
                </div>
                {errors.password && <p className='errorForm'> {errors.password.message}</p>}

                <button className='btn btn-outline-info mt-2' type="submit">Ingresar</button>
              </form>

            </div>



          </div>
        </div>


      </div>

    </React.Fragment>
  )
}

export default Login