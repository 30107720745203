import jQuery from 'jquery';
import Highcharts from 'highcharts/highstock';
import axios from 'axios'
import { search_selected } from './utils';
import buttonprediction from '../Components/ButtonPrediction';
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);
require("highcharts/highcharts-more.js")(Highcharts);


export const chart_container_of = (container, nombre, data1, data2, tipo, fecha_inicio, fecha_termino, numeroLocalidad) => {

    if (tipo === 'Localidad') {
        console.log(data1);
        console.log(data2);

        Highcharts.stockChart(container, {
            chart: {
                zoomType: 'xy',
                type: 'area',
                events:{
                    render: function() {
                        jQuery(this.container).find(".highcharts-title").html(
                            '<i class="far fa-chart-bar"></i> <span>Facturación</span>' +
                            '<div style="display: inline-block; margin-left: 10px;"><button style="background-color: #0e89dd; border: none; color: white; padding: 6px 12px; cursor: pointer; border-radius: 3px; font-size: 12px;" id="xgboost">xgboost</button></div>' +
                            '<div style="display: inline-block; margin-left: 5px;"><button style="background-color: #0e89dd; border: none; color: white; padding: 6px 12px; cursor: pointer; border-radius: 3px; font-size: 12px;" id="random_forest">Random forest</button></div>' +
                            '<div style="display: inline-block; margin-left: 5px;"><button style="background-color: #0e89dd; border: none; color: white; padding: 6px 12px; cursor: pointer; border-radius: 3px; font-size: 12px;" id="mlp">Red neuronal</button></div>'
                        );
                        const chart = this;   
                        
                        jQuery("#xgboost").off().on("click", function(event) {
                            buttonprediction(chart, tipo.toLowerCase(), numeroLocalidad.toString(), 'None', 'demanda', data2, event.target.id);                            
                            jQuery('.custom-button').removeClass('selected').css("background-color", "#0e89dd");
                            jQuery(this).addClass('selected').css("background-color", "#0c76c0");
                        }).mouseover(function() {
                            if (!jQuery(this).hasClass('selected')) {
                                jQuery(this).css("background-color", "#0c76c0");
                            }
                        }).mouseout(function() {
                            if (!jQuery(this).hasClass('selected')) {
                                jQuery(this).css("background-color", "#0e89dd");
                            }
                        })
                
                        jQuery("#random_forest").off().on("click", function(event) {
                            buttonprediction(chart, tipo.toLowerCase(), numeroLocalidad.toString(), 'None', 'demanda', data2, event.target.id);
                            jQuery('.custom-button').removeClass('selected').css("background-color", "#0e89dd");
                            jQuery(this).addClass('selected').css("background-color", "#0c76c0");
                        }).mouseover(function() {
                            if (!jQuery(this).hasClass('selected')) {
                                jQuery(this).css("background-color", "#0c76c0");
                            }
                        }).mouseout(function() {
                            if (!jQuery(this).hasClass('selected')) {
                                jQuery(this).css("background-color", "#0e89dd");
                            }
                        });
                
                        jQuery("#mlp").off().on("click", function(event) {
                            buttonprediction(chart, tipo.toLowerCase(), numeroLocalidad.toString(), 'None', 'demanda', data2, event.target.id);
                            jQuery('.custom-button').removeClass('selected').css("background-color", "#0e89dd");
                            jQuery(this).addClass('selected').css("background-color", "#0c76c0");
                        }).mouseover(function() {
                            if (!jQuery(this).hasClass('selected')) {
                                jQuery(this).css("background-color", "#0c76c0");
                            }
                        }).mouseout(function() {
                            if (!jQuery(this).hasClass('selected')) {
                                jQuery(this).css("background-color", "#0e89dd");
                            }
                        });                                             
                    }
                }
                                
                 
            },

            exporting: {
                enabled: true
              },
              credits: {
                enabled: false
            },

            rangeSelector: {
                enabled: false
            },
            navigation: {
                buttonOptions: {

                }

            },


            title: {
                useHTML: true,
                text: '<i class="far fa-chart-bar"></i> <span>Facturación</span><button id="xgboost" class="custom-button" style="margin-left: 10px;">xgboost</button><button id="random_forest" class="custom-button" style="margin-left: 5px;">Random forest</button><button id="mlp" class="custom-button" style="margin-left: 5px;">Red neuronal</button>',
                style: {

                    fontSize: '16px',
                },
                align: 'left',
                x: 0,
                y: 10

            },
            xAxis: {

                type: 'datetime'
            },
            yAxis: {
                opposite: false,
                min: 0,
                title: {
                    useHTML: true,
                    text: '&nbsp;[m&sup3;]'
                }
            },
            legend: {
                enabled: true
            },
            // plotOptions: {

            //   series: {
            //       stacking: 'normal'
            //   }
            // },

            series: [{
                useHTML: true,

                name: 'Facturación [m³]',
                data: data2,
                color: "#0e89dd"

            }, {
                name: 'Produccion [m³]',
                data: data1,
                color: "#b9dcf9"

            }]
        });
    } else if (tipo === 'Cuartel') {

        Highcharts.stockChart(container, {

            chart: {
                zoomType: 'xy',
                type: 'area',
                events:{
                    render: function() {
                        jQuery(this.container).find(".highcharts-title").html(
                            '<i class="far fa-chart-bar"></i> <span>Facturación</span>' +
                            '<div style="display: inline-block; margin-left: 10px;"><button style="background-color: #0e89dd; border: none; color: white; padding: 6px 12px; cursor: pointer; border-radius: 3px; font-size: 12px;" id="xgboost">xgboost</button></div>' +
                            '<div style="display: inline-block; margin-left: 5px;"><button style="background-color: #0e89dd; border: none; color: white; padding: 6px 12px; cursor: pointer; border-radius: 3px; font-size: 12px;" id="random_forest">Random forest</button></div>' +
                            '<div style="display: inline-block; margin-left: 5px;"><button style="background-color: #0e89dd; border: none; color: white; padding: 6px 12px; cursor: pointer; border-radius: 3px; font-size: 12px;" id="mlp">Red neuronal</button></div>'
                        );
                        const chart = this;   
                        
                        jQuery("#xgboost").off().on("click", function(event) {
                            buttonprediction(chart, tipo.toLowerCase(), nombre, 'None', 'demanda', data2, event.target.id);                            
                            jQuery('.custom-button').removeClass('selected').css("background-color", "#0e89dd");
                            jQuery(this).addClass('selected').css("background-color", "#0c76c0");
                        }).mouseover(function() {
                            if (!jQuery(this).hasClass('selected')) {
                                jQuery(this).css("background-color", "#0c76c0");
                            }
                        }).mouseout(function() {
                            if (!jQuery(this).hasClass('selected')) {
                                jQuery(this).css("background-color", "#0e89dd");
                            }
                        })
                
                        jQuery("#random_forest").off().on("click", function(event) {
                            buttonprediction(chart, tipo.toLowerCase(), nombre, 'None', 'demanda', data2, event.target.id);
                            jQuery('.custom-button').removeClass('selected').css("background-color", "#0e89dd");
                            jQuery(this).addClass('selected').css("background-color", "#0c76c0");
                        }).mouseover(function() {
                            if (!jQuery(this).hasClass('selected')) {
                                jQuery(this).css("background-color", "#0c76c0");
                            }
                        }).mouseout(function() {
                            if (!jQuery(this).hasClass('selected')) {
                                jQuery(this).css("background-color", "#0e89dd");
                            }
                        });
                
                        jQuery("#mlp").off().on("click", function(event) {
                            buttonprediction(chart, tipo.toLowerCase(), nombre, 'None', 'demanda', data2, event.target.id);
                            jQuery('.custom-button').removeClass('selected').css("background-color", "#0e89dd");
                            jQuery(this).addClass('selected').css("background-color", "#0c76c0");
                        }).mouseover(function() {
                            if (!jQuery(this).hasClass('selected')) {
                                jQuery(this).css("background-color", "#0c76c0");
                            }
                        }).mouseout(function() {
                            if (!jQuery(this).hasClass('selected')) {
                                jQuery(this).css("background-color", "#0e89dd");
                            }
                        });                                             
                    }
                }
                                
                 
            },

            exporting: {
                enabled: true
              },
              credits: {
                enabled: false
            },

            rangeSelector: {
                enabled: false
            },
            navigation: {
                buttonOptions: {

                }

            },


            title: {
                useHTML: true,
                text: '<i class="far fa-chart-bar"></i> <span>Facturación</span><button id="xgboost" class="custom-button" style="margin-left: 10px;">xgboost</button><button id="random_forest" class="custom-button" style="margin-left: 5px;">Random forest</button><button id="mlp" class="custom-button" style="margin-left: 5px;">Red Neuronal</button>',
                style: {

                    fontSize: '16px',
                },
                align: 'left',
                x: 0,
                y: 10

            },

            xAxis: {

                type: 'datetime'
            },
            yAxis: {
                opposite: false,
                min: 0,
                title: {
                    useHTML: true,
                    text: '&nbsp;[m&sup3;]'
                }
            },
            legend: {
                enabled: true
            },
            // plotOptions: {

            //   series: {
            //       stacking: 'normal'
            //   }
            // },

            series: [{
                name: 'Facturacion [m³]',
                data: data2,
                color: "#0e89dd"

            }]
        });
    } else if (tipo === 'Cliente') {

        Highcharts.stockChart(container, {

            chart: {
                zoomType: 'xy',
                type: 'area',
            },

            credits: {
                enabled: false
            },

            rangeSelector: {
                selected: 5,
                inputEnabled: false,
                buttonTheme: {
                    visibility: 'hidden'
                },
                labelStyle: {
                    visibility: 'hidden'
                },
                inputPosition: {
                    align: 'center',
                },
            },

            navigation: {
                buttonOptions: {

                }
            },

            title: {
                useHTML: true,
                text: '<i class="far fa-chart-bar"></i> <span>Facturación<span>',
                style: {

                    fontSize: '16px',
                },
                align: 'left',
                x: 0,
                y: 10

            },
            xAxis: {

                type: 'datetime'
            },
            yAxis: {
                opposite: false,
                min: 0,
                title: {
                    useHTML: true,
                    text: '&nbsp;[m&sup3;]'
                }
            },
            legend: {
                enabled: true
            },
            // plotOptions: {

            //   series: {
            //       stacking: 'normal'
            //   }
            // },

            series: [{
                name: 'Facturacion [m³]',
                data: data2,
                color: "#0e89dd"

            }]
        });
    }

}

export const chart_container_ranking_demanda = (nombre, data1, tipo, fecha_inicio, fecha_termino) => {


    if (tipo === 'Localidad') {
        Highcharts.chart('container', {
            chart: {
                type: 'column'
            },
            plotOptions: {
                series: {
                    cursor: 'pointer',
                    point: {
                        events: {
                            click: function () {
                                let tipo_ranking = 'Cuartel'
                                var location = this.name;


                                (async function () {
                                    console.log(location)
                                    search_selected(`${location} (Cuartel)`, '', '', 'Cuartel', 'Resumen', true, { numeroC: location }, true)

                                })();

                            }
                        }
                    }
                }
            },
            title: {
                useHTML: true,
                text: '<i class="far fa-chart-bar"></i> <span>Ranking Facturación<span>',
                style: {

                    fontSize: '16px',
                },
                align: 'left',
                x: 0,
                y: 10

            },
            credits: {
                enabled: false
            },
            xAxis: {
                type: 'category',
                title: {
                    text: 'Cuartel',
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif'
                    },
                },

                // labels: {
                //     rotation: -45,
                //     style: {
                //         fontSize: '13px',
                //         fontFamily: 'Verdana, sans-serif'
                //     }
                // }
            },
            yAxis: {
                min: 0,
                title: {
                    text: '[m3]'
                }
            },
            legend: {
                enabled: false
            },
            // tooltip: {
            //     pointFormat: 'Population in 2017: <b>{point.y:.1f} millions</b>'
            // },
            series: [{
                name: 'Facturación',
                data: data1,
                color: '#0e89dd',
                dataLabels: {
                    enabled: true,
                    // rotation: -90,
                    color: '#333',
                    // align: 'right',
                    // format: '{point.y:.1f}', // one decimal
                    // y: 10, // 10 pixels down from the top
                    // style: {
                    //     fontSize: '13px',
                    //     fontFamily: 'Verdana, sans-serif'
                    // }
                }
            }]
        });
    } else if (tipo === 'Cuartel') {
        Highcharts.chart('container', {
            chart: {
                type: 'column'
            },
            plotOptions: {
                series: {
                    cursor: 'pointer',
                    // point: {
                    //     events: {
                    //         click: function () {
                    //               tipo_ranking = 'Cliente'
                    //               var location = this.name;
                    //               (async function(){

                    //                 var request_location= await search_selected_highcharts_comuna(location, tipo_ranking);
                    //                 var data_chart = JSON.parse(request_location.data);
                    //                 var data_selected =  data_chart[0];
                    //                 var numero = data_selected.Numero;
                    //                 var numero_str = numero.toString();
                    //                 var nombre = data_selected.Nombre;
                    //                 var lat = data_selected.lat;
                    //                 var lon = data_selected.lon;
                    //                 var tipos = data_selected.Tipo[0]
                    //                 map.setView([lat, lon], 17);
                    //                 map.removeLayer(theMarker);
                    //                 theMarker  = new L.Marker([lat, lon]).addTo(map);
                    //                 theMarker.bindPopup('<p>' + nombre + ' (' + tipos +')</p>').openPopup();
                    //                 theMarker.bindPopup('<p>' + nombre + ' (' + tipos +')</p>').openPopup();
                    //              })();

                    //         }
                    //     }
                    // }
                }
            },
            title: {
                useHTML: true,
                text: '<i class="far fa-chart-bar"></i> <span>Ranking Facturación<span>',
                style: {

                    fontSize: '16px',
                },
                align: 'left',
                x: 0,
                y: 10

            },
            credits: {
                enabled: false
            },
            xAxis: {
                type: 'category',
                title: {
                    text: 'Cliente',
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif'
                    },
                },

                // labels: {
                //     rotation: -45,
                //     style: {
                //         fontSize: '13px',
                //         fontFamily: 'Verdana, sans-serif'
                //     }
                // }
            },
            yAxis: {
                min: 0,
                title: {
                    text: '[m3]'
                }
            },
            legend: {
                enabled: false
            },
            // tooltip: {
            //     pointFormat: 'Population in 2017: <b>{point.y:.1f} millions</b>'
            // },
            series: [{
                name: 'Facturación',
                data: data1,
                color: '#0e89dd',
                dataLabels: {
                    enabled: true,
                    // rotation: -90,
                    color: '#333',
                    // align: 'right',
                    // format: '{point.y:.1f}', // one decimal
                    // y: 10, // 10 pixels down from the top
                    // style: {
                    //     fontSize: '13px',
                    //     fontFamily: 'Verdana, sans-serif'
                    // }
                }
            }]
        });
    }


}



// export const chart_container_ranking_demanda = (nombre, data1, tipo, fecha_inicio, fecha_termino) => {


//     if (tipo === 'Localidad') {
//         Highcharts.chart('container', {
//             chart: {
//                 type: 'column'
//             },
//             plotOptions: {
//                 series: {
//                     cursor: 'pointer',
//                     point: {
//                         events: {
//                             click: function () {
//                                 let tipo_ranking = 'Cuartel'
//                                 var location = this.name;

                             
//                                 (async function(){
//                                   console.log(location)
//                                   search_selected(`${location} (Cuartel)`, '', '', 'Cuartel', 'Resumen', true, { numeroC: location },true)
                              
//                                })();

//                           }
//                         }
//                     }
//                 }
//             },
//             title: {
//                 useHTML: true,
//                 text: '<i class="far fa-chart-bar"></i> <span>Ranking Facturación<span>',
//                 style: {

//                     fontSize: '16px',
//                 },
//                 align: 'left',
//                 x: 0,
//                 y: 10

//             },
//             credits: {
//                 enabled: false
//             },
//             xAxis: {
//                 type: 'category',
//                 title: {
//                     text: 'Cuartel',
//                     style: {
//                         fontSize: '13px',
//                         fontFamily: 'Verdana, sans-serif'
//                     },
//                 },

//                 // labels: {
//                 //     rotation: -45,
//                 //     style: {
//                 //         fontSize: '13px',
//                 //         fontFamily: 'Verdana, sans-serif'
//                 //     }
//                 // }
//             },
//             yAxis: {
//                 min: 0,
//                 title: {
//                     text: 'Facturacion [m³]'
//                 }
//             },
//             legend: {
//                 enabled: false
//             },
//             // tooltip: {
//             //     pointFormat: 'Population in 2017: <b>{point.y:.1f} millions</b>'
//             // },
//             series: [{
//                 name: 'Facturacion [m³]',
//                 data: data1,
//                 color: '#0e89dd',
//                 dataLabels: {
//                     enabled: true,
//                     // rotation: -90,
//                     color: '#333',
//                     // align: 'right',
//                     // format: '{point.y:.1f}', // one decimal
//                     // y: 10, // 10 pixels down from the top
//                     // style: {
//                     //     fontSize: '13px',
//                     //     fontFamily: 'Verdana, sans-serif'
//                     // }
//                 }
//             }]
//         });

//     } else if (tipo === 'Cuartel') {
//         Highcharts.chart('container', {
//             chart: {
//                 type: 'column'
//             },
//             plotOptions: {
//                 series: {
//                     cursor: 'pointer',
//                     // point: {
//                     //     events: {
//                     //         click: function () {
//                     //               tipo_ranking = 'Cliente'
//                     //               var location = this.name;
//                     //               (async function(){
//                     //                 console.log(location)
//                     //                 var request_location= await search_selected_highcharts_comuna(location, tipo_ranking);
//                     //                 var data_chart = JSON.parse(request_location.data);
//                     //                 var data_selected =  data_chart[0];
//                     //                 var numero = data_selected.Numero;
//                     //                 var numero_str = numero.toString();
//                     //                 var nombre = data_selected.Nombre;
//                     //                 var lat = data_selected.lat;
//                     //                 var lon = data_selected.lon;
//                     //                 var tipos = data_selected.Tipo[0]
//                     //                 map.setView([lat, lon], 17);
//                     //                 map.removeLayer(theMarker);
//                     //                 theMarker  = new L.Marker([lat, lon]).addTo(map);
//                     //                 theMarker.bindPopup('<p>' + nombre + ' (' + tipos +')</p>').openPopup();
//                     //                 theMarker.bindPopup('<p>' + nombre + ' (' + tipos +')</p>').openPopup();
//                     //              })();

//                     //         }
//                     //     }
//                     // }
//                 }
//             },
//             title: {
//                 useHTML: true,
//                 text: '<i class="far fa-chart-bar"></i> <span>Ranking Facturación<span>',
//                 style: {

//                     fontSize: '16px',
//                 },
//                 align: 'left',
//                 x: 0,
//                 y: 10

//             },
//             credits: {
//                 enabled: false
//             },
//             xAxis: {
//                 type: 'category',
//                 title: {
//                     text: 'Cliente',
//                     style: {
//                         fontSize: '13px',
//                         fontFamily: 'Verdana, sans-serif'
//                     },
//                 },

//                 // labels: {
//                 //     rotation: -45,
//                 //     style: {
//                 //         fontSize: '13px',
//                 //         fontFamily: 'Verdana, sans-serif'
//                 //     }
//                 // }
//             },
//             yAxis: {
//                 min: 0,
//                 title: {
//                     text: 'Facturacion [m³]'
//                 }
//             },
//             legend: {
//                 enabled: false
//             },
//             // tooltip: {
//             //     pointFormat: 'Population in 2017: <b>{point.y:.1f} millions</b>'
//             // },
//             series: [{
//                 name: 'Facturacion [m³]',
//                 data: data1,
//                 color: '#0e89dd',
//                 dataLabels: {
//                     enabled: true,
//                     // rotation: -90,
//                     color: '#333',
//                     // align: 'right',
//                     // format: '{point.y:.1f}', // one decimal
//                     // y: 10, // 10 pixels down from the top
//                     // style: {
//                     //     fontSize: '13px',
//                     //     fontFamily: 'Verdana, sans-serif'
//                     // }
//                 }
//             }]
//         });






//     }



// }


export const get_data_chart_cliente_demanda = async (numero_cliente, fecha_inicio, fecha_termino) => {

    try {


        // GET a list of book IDs of the current user
        const request_chart_cliente_demanda = await axios.post(`${process.env.REACT_APP_IP_ADDRESS}/graph_axios_clientes_demanda`,
            {
                'numero_cliente': numero_cliente,
                'fecha_inicio': fecha_inicio, 'fecha_termino': fecha_termino
            }).then((response) => {

                const data_chart = response.data;
                const data_demanda = JSON.parse(data_chart);


                const json_data_demanda = data_demanda.consumo;
                const result_demanda = [];
                for (let i in json_data_demanda)
                    result_demanda.push([i, json_data_demanda[i]]);

                for (let j in result_demanda)
                    result_demanda[j][0] = parseInt(result_demanda[j][0])

                const result_oferta = 0;
                chart_container_of('container', numero_cliente, result_oferta, result_demanda, 'Cliente', fecha_inicio, fecha_termino)
            });

        // GET information about each book
        return await request_chart_cliente_demanda
    } catch (error) {
        // If any of the awaited promises was rejected, this catch block
        // would catch the rejection reason
        return null;
    }
}