import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Main from './Pages/Main/main';
import Login from './Pages/Login/login';

function App() {
  const user = localStorage.getItem('token');
  return (
    <BrowserRouter>


      <Routes>

        {user && <Route path='/main' exact element={<Main />} />}
        <Route exact path='/main' name='main' element={<Login />} />

        {user && <Route path='/' exact element={<Main />} />}
        <Route exact path='/' name='login' element={<Login />} />
        
      </Routes>



    </BrowserRouter>
  );
}

export default App;
