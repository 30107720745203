import jQuery from 'jquery';
import Highcharts from "highcharts";
import Highcharts2 from 'highcharts/highstock';
import { search_selected } from './utils';
import buttonprediction from '../Components/ButtonPrediction';
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);
require("highcharts/highcharts-more.js")(Highcharts2);

export const chart_reclamos_motivo=(container, nombre,data1,tipo, fecha_inicio, fecha_termino)=>
{

    if(tipo === 'Localidad'){
        console.log(`segundo grafico ${data1}`)
    
        // let btn = document.createElement("button");

        // btn.innerHTML = "Subscribe";
        
        // btn.onclick = function () {
        
        // alert("Button is clicked");
        
        // };
        
        // document.body.appendChild(btn);
      Highcharts.chart(container, {
          chart: {
              type: 'column'
          },
          title: {
              useHTML: true,
              text: '<i class="far fa-chart-bar"></i> <span>Motivo Reclamos<span>',
              style: {
  
                  fontSize: '16px',
              },
              align: 'left',
              x: 0,
              y: 10
              
          },
          credits: {
          enabled: false
          },
          xAxis: {
              type: 'category',
              // labels: {
              //     rotation: -45,
              //     style: {
              //         fontSize: '13px',
              //         fontFamily: 'Verdana, sans-serif'
              //     }
              // }
          },
          yAxis: {
              min: 0,
              title: {
                  text: 'Cantidad'
              }
          },
          legend: {
              enabled: false
          },
          // tooltip: {
          //     pointFormat: 'Population in 2017: <b>{point.y:.1f} millions</b>'
          // },
          series: [{
              name: 'Reclamos',
              data: data1,
              color:'#0e89dd',
              dataLabels: {
                  enabled: true,
                  // rotation: -90,
                  color: '#333',
                  // align: 'right',
                  // format: '{point.y:.1f}', // one decimal
                  // y: 10, // 10 pixels down from the top
                  // style: {
                  //     fontSize: '13px',
                  //     fontFamily: 'Verdana, sans-serif'
                  // }
              }
          }],  exporting: {
            enabled: true
        }
      });
    }else if(tipo === 'Cuartel'){
  
      Highcharts.chart(container, {
          chart: {
              type: 'column'
          },
          title: {
              useHTML: true,
              text: '<i class="far fa-chart-bar"></i> <span>Motivo Reclamos<span>',
              style: {
  
                  fontSize: '16px',
              },
              align: 'left',
              x: 0,
              y: 10
  
          },
          credits: {
          enabled: false
          },
          xAxis: {
              type: 'category',
              // labels: {
              //     rotation: -45,
              //     style: {
              //         fontSize: '13px',
              //         fontFamily: 'Verdana, sans-serif'
              //     }
              // }
          },
          yAxis: {
              min: 0,
              title: {
                  text: 'Cantidad'
              }
          },
          legend: {
              enabled: false
          },
          // tooltip: {
          //     pointFormat: 'Population in 2017: <b>{point.y:.1f} millions</b>'
          // },
          series: [{
              name: 'Reclamos',
              data: data1,
              color:'#0e89dd',
              dataLabels: {
                  enabled: true,
                  // rotation: -90,
                  color: '#333',
                  // align: 'right',
                  // format: '{point.y:.1f}', // one decimal
                  // y: 10, // 10 pixels down from the top
                  // style: {
                  //     fontSize: '13px',
                  //     fontFamily: 'Verdana, sans-serif'
                  // }
              }
          }]
      });
    }
  
  }

export const chart_reclamos =(container, nombre, data, tipo, fecha_inicio,fecha_termino,numeroLocalidad)=>{

  
    if (tipo === 'Localidad'){
      console.log(data)
      Highcharts2.stockChart(container, {
  
          chart: {
              zoomType: 'xy',
              events:{
                  render: function() {
                      jQuery(this.container).find(".highcharts-title").html(
                          '<i class="far fa-chart-bar"></i> <span>Reclamos</span>' +
                          '<div style="display: inline-block; margin-left: 10px;"><button style="background-color: #0e89dd; border: none; color: white; padding: 6px 12px; cursor: pointer; border-radius: 3px; font-size: 12px;" id="xgboost">xgboost</button></div>' +
                          '<div style="display: inline-block; margin-left: 5px;"><button style="background-color: #0e89dd; border: none; color: white; padding: 6px 12px; cursor: pointer; border-radius: 3px; font-size: 12px;" id="random_forest">Random forest</button></div>' +
                          '<div style="display: inline-block; margin-left: 5px;"><button style="background-color: #0e89dd; border: none; color: white; padding: 6px 12px; cursor: pointer; border-radius: 3px; font-size: 12px;" id="mlp">Red neuronal</button></div>'
                      );
                      const chart = this;   
                      
                      jQuery("#xgboost").off().on("click", function(event) {
                          buttonprediction(chart, tipo.toLowerCase(), numeroLocalidad.toString(), 'None', 'reclamos', data, event.target.id);                            
                          jQuery('.custom-button').removeClass('selected').css("background-color", "#0e89dd");
                          jQuery(this).addClass('selected').css("background-color", "#0c76c0");
                      }).mouseover(function() {
                          if (!jQuery(this).hasClass('selected')) {
                              jQuery(this).css("background-color", "#0c76c0");
                          }
                      }).mouseout(function() {
                          if (!jQuery(this).hasClass('selected')) {
                              jQuery(this).css("background-color", "#0e89dd");
                          }
                      })
              
                      jQuery("#random_forest").off().on("click", function(event) {
                          buttonprediction(chart, tipo.toLowerCase(), numeroLocalidad.toString(), 'None', 'reclamos', data, event.target.id);
                          jQuery('.custom-button').removeClass('selected').css("background-color", "#0e89dd");
                          jQuery(this).addClass('selected').css("background-color", "#0c76c0");
                      }).mouseover(function() {
                          if (!jQuery(this).hasClass('selected')) {
                              jQuery(this).css("background-color", "#0c76c0");
                          }
                      }).mouseout(function() {
                          if (!jQuery(this).hasClass('selected')) {
                              jQuery(this).css("background-color", "#0e89dd");
                          }
                      });
              
                      jQuery("#mlp").off().on("click", function(event) {
                          buttonprediction(chart, tipo.toLowerCase(), numeroLocalidad.toString(), 'None', 'reclamos', data, event.target.id);
                          jQuery('.custom-button').removeClass('selected').css("background-color", "#0e89dd");
                          jQuery(this).addClass('selected').css("background-color", "#0c76c0");
                      }).mouseover(function() {
                          if (!jQuery(this).hasClass('selected')) {
                              jQuery(this).css("background-color", "#0c76c0");
                          }
                      }).mouseout(function() {
                          if (!jQuery(this).hasClass('selected')) {
                              jQuery(this).css("background-color", "#0e89dd");
                          }
                      });                                             
                  }
              }
                  
          },
  
          credits: {
          enabled: false
          },
  
          rangeSelector: {
            enabled: false
        },
        navigation: {
            buttonOptions: {

            }
        },
  
          title: {
              useHTML: true,
              text: '<i class="far fa-chart-bar"></i> <span>Reclamos</span><button id="xgboost" class="custom-button" style="margin-left: 10px;">xgboost</button><button id="random_forest" class="custom-button" style="margin-left: 5px;">Random forest</button><button id="mlp" class="custom-button" style="margin-left: 5px;">Red neuronal</button>',
              style: {
  
                  fontSize: '16px',
              },
              align: 'left',
              x: 0,
              y: 10
  
          },
          xAxis: {
  
              type: 'datetime'
          },
          yAxis: {
              opposite:false,
              min: 0,
              title: {
  
                  text: 'Número de Reclamos'
              }
          },
          legend: {
              enabled: true
          },
          plotOptions: {
              area: {
                  fillColor: {
                      linearGradient: {
                          x1: 0,
                          y1: 0,
                          x2: 0,
                          y2: 1
                      },
                      stops: [
                          [0, Highcharts.getOptions().colors[0]],
                          [1, Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
                      ]
                  },
                  marker: {
                      radius: 2
                  },
                  lineWidth: 1,
                  states: {
                      hover: {
                          lineWidth: 1
                      }
                  },
                  threshold: null
              }
          },
  
          series: [{
              type: 'area',
              name: 'Reclamos',
              data: data,
  
          }],
            /*MODIFICACION YERKO*/
            // exporting: {
            //     buttons: [{
            //         text: 'Predecir',
            //         onclick: function () {
            //         buttonprediction(this, tipo.toLowerCase(), numeroLocalidad.toString(), 'None', 'reclamos', data, 'xgboost');
            //         },
            //         theme: {
            //             'stroke-width': 1,
            //             stroke: 'silver',
            //             r: 5,
            //             fill: '#6DC8F3',
            //             color: 'white',
            //             states: {
            //                 hover: {
            //                     fill: '#6DC8F3' 
                                
            //                 },
            //                 select: {
            //                     stroke: '#039',
            //                     fill: '#6DC8F3'
            //                 } 
            //             },
            //             style: {
            //                 color: '#FFFFFF',
            //                 //font: 'bold 12px "Trebuchet MS", Verdana, sans-serif',
            //             },
                        

            //         }
            //     }]
            // }
      });
    }else if (tipo === 'Cuartel'){
  
        Highcharts2.stockChart(container, {
  
          chart: {
              zoomType: 'xy',
              events:{
                  render: function() {
                      jQuery(this.container).find(".highcharts-title").html(
                          '<i class="far fa-chart-bar"></i> <span>Reclamos</span>' +
                          '<div style="display: inline-block; margin-left: 10px;"><button style="background-color: #0e89dd; border: none; color: white; padding: 6px 12px; cursor: pointer; border-radius: 3px; font-size: 12px;" id="xgboost">xgboost</button></div>' +
                          '<div style="display: inline-block; margin-left: 5px;"><button style="background-color: #0e89dd; border: none; color: white; padding: 6px 12px; cursor: pointer; border-radius: 3px; font-size: 12px;" id="random_forest">Random forest</button></div>' +
                          '<div style="display: inline-block; margin-left: 5px;"><button style="background-color: #0e89dd; border: none; color: white; padding: 6px 12px; cursor: pointer; border-radius: 3px; font-size: 12px;" id="mlp">Red neuronal</button></div>'
                      );
                      const chart = this;   
                      
                      jQuery("#xgboost").off().on("click", function(event) {
                          buttonprediction(chart, tipo.toLowerCase(), nombre, 'None', 'reclamos', data, event.target.id);                            
                          jQuery('.custom-button').removeClass('selected').css("background-color", "#0e89dd");
                          jQuery(this).addClass('selected').css("background-color", "#0c76c0");
                      }).mouseover(function() {
                          if (!jQuery(this).hasClass('selected')) {
                              jQuery(this).css("background-color", "#0c76c0");
                          }
                      }).mouseout(function() {
                          if (!jQuery(this).hasClass('selected')) {
                              jQuery(this).css("background-color", "#0e89dd");
                          }
                      })
              
                      jQuery("#random_forest").off().on("click", function(event) {
                          buttonprediction(chart, tipo.toLowerCase(), nombre, 'None', 'reclamos', data, event.target.id);
                          jQuery('.custom-button').removeClass('selected').css("background-color", "#0e89dd");
                          jQuery(this).addClass('selected').css("background-color", "#0c76c0");
                      }).mouseover(function() {
                          if (!jQuery(this).hasClass('selected')) {
                              jQuery(this).css("background-color", "#0c76c0");
                          }
                      }).mouseout(function() {
                          if (!jQuery(this).hasClass('selected')) {
                              jQuery(this).css("background-color", "#0e89dd");
                          }
                      });
              
                      jQuery("#mlp").off().on("click", function(event) {
                          buttonprediction(chart, tipo.toLowerCase(), nombre, 'None', 'reclamos', data, event.target.id);
                          jQuery('.custom-button').removeClass('selected').css("background-color", "#0e89dd");
                          jQuery(this).addClass('selected').css("background-color", "#0c76c0");
                      }).mouseover(function() {
                          if (!jQuery(this).hasClass('selected')) {
                              jQuery(this).css("background-color", "#0c76c0");
                          }
                      }).mouseout(function() {
                          if (!jQuery(this).hasClass('selected')) {
                              jQuery(this).css("background-color", "#0e89dd");
                          }
                      });                                             
                  }
              }
                  
          },
  
          credits: {
          enabled: false
          },
  
          rangeSelector: {
           enabled: false,

           },
  
          navigation: {
              buttonOptions: {
  
              }
          },
  
          title: {
              useHTML: true,
              text: '<i class="far fa-chart-bar"></i> <span>Reclamos</span><button id="xgboost" class="custom-button" style="margin-left: 10px;">xgboost</button><button id="random_forest" class="custom-button" style="margin-left: 5px;">Random forest</button><button id="mlp" class="custom-button" style="margin-left: 5px;">Red neuronal</button>',
              style: {
  
                  fontSize: '16px',
              },
              align: 'left',
              x: 0,
              y: 10
  
          },
          xAxis: {
  
              type: 'datetime'
          },
          yAxis: {
              opposite:false,
              min: 0,
              title: {
  
                  text: 'Número de Reclamos'
              }
          },
          legend: {
              enabled: false
          },
          plotOptions: {
              area: {
                  fillColor: {
                      linearGradient: {
                          x1: 0,
                          y1: 0,
                          x2: 0,
                          y2: 1
                      },
                      stops: [
                          [0, Highcharts.getOptions().colors[0]],
                          [1, Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
                      ]
                  },
                  marker: {
                      radius: 2
                  },
                  lineWidth: 1,
                  states: {
                      hover: {
                          lineWidth: 1
                      }
                  },
                  threshold: null
              }
          },
  
          series: [{
              type: 'area',
              name: 'Reclamos',
              data: data,
  
          }],
        // /*MODIFICACION YERKO*/
        // exporting: {
        //     buttons: [{
        //         text: 'Predecir',
        //         onclick: function () {
        //         buttonprediction(this, tipo.toLowerCase(), nombre, 'None', 'reclamos', data);
        //         },
        //         theme: {
        //             'stroke-width': 1,
        //             stroke: 'silver',
        //             r: 5,
        //             fill: '#6DC8F3',
        //             color: 'white',
        //             states: {
        //                 hover: {
        //                     fill: '#6DC8F3' 
                            
        //                 },
        //                 select: {
        //                     stroke: '#039',
        //                     fill: '#6DC8F3'
        //                 } 
        //             },
        //             style: {
        //                 color: '#FFFFFF',
        //                 //font: 'bold 12px "Trebuchet MS", Verdana, sans-serif',
        //             },
                    

        //         }
        //     }]
        // }
      });
    }
  
  }

export const chart_reclamos_canal=(container, nombre,data1,tipo, fecha_inicio, fecha_termino)=>{


    if (tipo === 'Localidad'){
  
      Highcharts.chart(container, {
          chart: {
              type: 'column'
          },
          title: {
              useHTML: true,
              text: '<i class="far fa-chart-bar"></i> <span>Canal Reclamos<span>',
              style: {
  
                  fontSize: '16px',
              },
              align: 'left',
              x: 0,
              y: 10
  
          },
          credits: {
          enabled: false
          },
          xAxis: {
              type: 'category',
              // labels: {
              //     rotation: -45,
              //     style: {
              //         fontSize: '13px',
              //         fontFamily: 'Verdana, sans-serif'
              //     }
              // }
          },
          yAxis: {
              min: 0,
              title: {
                  text: 'Cantidad'
              }
          },
          legend: {
              enabled: false
          },
          // tooltip: {
          //     pointFormat: 'Population in 2017: <b>{point.y:.1f} millions</b>'
          // },
          series: [{
              name: 'Reclamos',
              data: data1,
              color:'#0e89dd',
              dataLabels: {
                  enabled: true,
                  // rotation: -90,
                  color: '#333',
                  // align: 'right',
                  // format: '{point.y:.1f}', // one decimal
                  // y: 10, // 10 pixels down from the top
                  // style: {
                  //     fontSize: '13px',
                  //     fontFamily: 'Verdana, sans-serif'
                  // }
              }
          }]
      });
  
    }else if (tipo === 'Cuartel'){
  
      Highcharts.chart(container, {
          chart: {
              type: 'column'
          },
          title: {
              useHTML: true,
              text: '<i class="far fa-chart-bar"></i> <span>Canal Reclamos<span>',
              style: {
  
                  fontSize: '16px',
              },
              align: 'left',
              x: 0,
              y: 10
  
          },
          credits: {
          enabled: false
          },
          xAxis: {
              type: 'category',
              // labels: {
              //     rotation: -45,
              //     style: {
              //         fontSize: '13px',
              //         fontFamily: 'Verdana, sans-serif'
              //     }
              // }
          },
          yAxis: {
              min: 0,
              title: {
                  text: 'Cantidad'
              }
          },
          legend: {
              enabled: false
          },
          // tooltip: {
          //     pointFormat: 'Population in 2017: <b>{point.y:.1f} millions</b>'
          // },
          series: [{
              name: 'Reclamos',
              data: data1,
              color:'#0e89dd',
              dataLabels: {
                  enabled: true,
                  // rotation: -90,
                  color: '#333',
                  // align: 'right',
                  // format: '{point.y:.1f}', // one decimal
                  // y: 10, // 10 pixels down from the top
                  // style: {
                  //     fontSize: '13px',
                  //     fontFamily: 'Verdana, sans-serif'
                  // }
              }
          }]
      });
  
    }
  }
  
export const chart_reclamos_resultado=(container, nombre,data1,tipo, fecha_inicio, fecha_termino)=>{

    if (tipo === 'Localidad'){
  
      Highcharts.chart(container, {
          chart: {
              type: 'column'
          },
          title: {
              useHTML: true,
              text: '<i class="far fa-chart-bar"></i> <span>Resultado Reclamos<span>',
              style: {
  
                  fontSize: '16px',
              },
              align: 'left',
              x: 0,
              y: 10
  
          },
          credits: {
          enabled: false
          },
          xAxis: {
              type: 'category',
              // labels: {
              //     rotation: -45,
              //     style: {
              //         fontSize: '13px',
              //         fontFamily: 'Verdana, sans-serif'
              //     }
              // }
          },
          yAxis: {
              min: 0,
              title: {
                  text: 'Cantidad'
              }
          },
          legend: {
              enabled: false
          },
          // tooltip: {
          //     pointFormat: 'Population in 2017: <b>{point.y:.1f} millions</b>'
          // },
          series: [{
              name: 'Reclamos',
              data: data1,
              color:'#0e89dd',
              dataLabels: {
                  enabled: true,
                  // rotation: -90,
                  color: '#333',
                  // align: 'right',
                  // format: '{point.y:.1f}', // one decimal
                  // y: 10, // 10 pixels down from the top
                  // style: {
                  //     fontSize: '13px',
                  //     fontFamily: 'Verdana, sans-serif'
                  // }
              }
          }]
      });
  
    }else if (tipo === 'Cuartel'){
  
      Highcharts.chart(container, {
          chart: {
              type: 'column'
          },
          title: {
              useHTML: true,
              text: '<i class="far fa-chart-bar"></i> <span>Resultado Reclamos<span>',
              style: {
  
                  fontSize: '16px',
              },
              align: 'left',
              x: 0,
              y: 10
  
          },
          credits: {
          enabled: false
          },
          xAxis: {
              type: 'category',
              // labels: {
              //     rotation: -45,
              //     style: {
              //         fontSize: '13px',
              //         fontFamily: 'Verdana, sans-serif'
              //     }
              // }
          },
          yAxis: {
              min: 0,
              title: {
                  text: 'Cantidad'
              }
          },
          legend: {
              enabled: false
          },
          // tooltip: {
          //     pointFormat: 'Population in 2017: <b>{point.y:.1f} millions</b>'
          // },
          series: [{
              name: 'Reclamos',
              data: data1,
              color:'#0e89dd',
              dataLabels: {
                  enabled: true,
                  // rotation: -90,
                  color: '#333',
                  // align: 'right',
                  // format: '{point.y:.1f}', // one decimal
                  // y: 10, // 10 pixels down from the top
                  // style: {
                  //     fontSize: '13px',
                  //     fontFamily: 'Verdana, sans-serif'
                  // }
              }
          }]
      });
  
    }
  
  
  }


export const chart_container_ranking_reclamos = (nombre, data1, tipo, fecha_inicio, fecha_termino)=>{


    if(tipo === 'Localidad'){
      Highcharts.chart('container', {
          chart: {
              type: 'column'
          },
          plotOptions: {
                series: {
                    cursor: 'pointer',
                    point: {
                        events: {
                            click: function () {
                                let tipo_ranking = 'Cuartel'
                                var location = this.name;

                             
                                (async function(){
                                  console.log(location)
                                  search_selected(`${location} (Cuartel)`, '', '', 'Cuartel', 'Resumen', true, { numeroC: location },true)
                              
                               })();

                          }
                        }
                    }
                 }
          },
          title: {
              useHTML: true,
              text: '<i class="far fa-chart-bar"></i> <span>Ranking Reclamos<span>',
              style: {
  
                  fontSize: '16px',
              },
              align: 'left',
              x: 0,
              y: 10
  
          },
          credits: {
          enabled: false
          },
          xAxis: {
              type: 'category',
                title: {
                    text: 'Cuartel',
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif'
                        },
                },
  
              // labels: {
              //     rotation: -45,
              //     style: {
              //         fontSize: '13px',
              //         fontFamily: 'Verdana, sans-serif'
              //     }
              // }
          },
          yAxis: {
              min: 0,
              title: {
                  text: 'Número de Reclamos'
              }
          },
          legend: {
              enabled: false
          },
          // tooltip: {
          //     pointFormat: 'Population in 2017: <b>{point.y:.1f} millions</b>'
          // },
          series: [{
              name: 'Reclamos',
              data: data1,
              color:'#0e89dd',
              dataLabels: {
                  enabled: true,
                  // rotation: -90,
                  color: '#333',
                  // align: 'right',
                  // format: '{point.y:.1f}', // one decimal
                  // y: 10, // 10 pixels down from the top
                  // style: {
                  //     fontSize: '13px',
                  //     fontFamily: 'Verdana, sans-serif'
                  // }
              }
          }]
      });
  
    }else if(tipo === 'Cuartel'){
      Highcharts.chart('container', {
          chart: {
              type: 'column'
          },
          plotOptions: {
                series: {
                    cursor: 'pointer',
                    // point: {
                    //     events: {
                    //         click: function () {
                    //               tipo_ranking = 'Cliente'
                    //               var location = this.name;
                    //               (async function(){
                    //                 console.log(location)
                    //                 var request_location= await search_selected_highcharts_comuna(location, tipo_ranking);
                    //                 var data_chart = JSON.parse(request_location.data);
                    //                 var data_selected =  data_chart[0];
                    //                 var numero = data_selected.Numero;
                    //                 var numero_str = numero.toString();
                    //                 var nombre = data_selected.Nombre;
                    //                 var lat = data_selected.lat;
                    //                 var lon = data_selected.lon;
                    //                 var tipos = data_selected.Tipo[0]
                    //                 map.setView([lat, lon], 17);
                    //                 map.removeLayer(theMarker);
                    //                 theMarker  = new L.Marker([lat, lon]).addTo(map);
                    //                 theMarker.bindPopup('<p>' + nombre + ' (' + tipos +')</p>').openPopup();
                    //                 theMarker.bindPopup('<p>' + nombre + ' (' + tipos +')</p>').openPopup();
                    //              })();
  
                    //         }
                    //     }
                    // }
                }
          },
          title: {
              useHTML: true,
              text: '<i class="far fa-chart-bar"></i> <span>Ranking Reclamos<span>',
              style: {
  
                  fontSize: '16px',
              },
              align: 'left',
              x: 0,
              y: 10
  
          },
          credits: {
          enabled: false
          },
          xAxis: {
              type: 'category',
                title: {
                    text: 'Cliente',
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif'
                        },
                },
  
              // labels: {
              //     rotation: -45,
              //     style: {
              //         fontSize: '13px',
              //         fontFamily: 'Verdana, sans-serif'
              //     }
              // }
          },
          yAxis: {
              min: 0,
              title: {
                  text: 'Número de Reclamos'
              }
          },
          legend: {
              enabled: false
          },
          // tooltip: {
          //     pointFormat: 'Population in 2017: <b>{point.y:.1f} millions</b>'
          // },
          series: [{
              name: 'Reclamos',
              data: data1,
              color:'#0e89dd',
              dataLabels: {
                  enabled: true,
                  // rotation: -90,
                  color: '#333',
                  // align: 'right',
                  // format: '{point.y:.1f}', // one decimal
                  // y: 10, // 10 pixels down from the top
                  // style: {
                  //     fontSize: '13px',
                  //     fontFamily: 'Verdana, sans-serif'
                  // }
              }
          }]
      });
  
    }
  
  
  
  
  }

