import React, { useEffect } from 'react'
import { } from "mapbox-gl-leaflet";
import { } from "mapbox-gl";
import {  mapaFuncion,LoadMap} from '../Helpers/funcionMap';



const ContainerMap = (props) => {
  
useEffect(()=> {
 setTimeout(()=>
 {
  LoadMap();
 },500)
   
 
},[])


  return (


    <React.Fragment>
      <div id="map-card">
        <div className="map-header">
          <div id="map-header-loc">
          <i className="fa-solid fa-map-location-dot"></i>&nbsp;&nbsp;
            <span id="map-header-text">Seleccionar Ubicación</span>
          </div>
          {/* <div id="map-header-date">01 enero, 2021 - 31 diciembre, 2021</div> */}
        </div>
        <div id='map'></div>
      </div>


    </React.Fragment>



  )
}

export default ContainerMap