import jQuery from 'jquery';
import Highcharts from 'highcharts/highstock';
import buttonprediction from '../Components/ButtonPrediction';
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);
require("highcharts/highcharts-more.js")(Highcharts);

export const chart_sector_calidad_turbiedad = (container, nombre, data, tipo, fecha_inicio, fecha_termino) => {

    Highcharts.stockChart(container, {


        chart: {
            zoomType: 'xy',
            events:{
                render: function() {
                    jQuery(this.container).find(".highcharts-title").html(
                        '<i class="far fa-chart-bar"></i> <span>Turbiedad</span>' +
                        '<div style="display: inline-block; margin-left: 10px;"><button style="background-color: #0e89dd; border: none; color: white; padding: 6px 12px; cursor: pointer; border-radius: 3px; font-size: 12px;" id="xgboost">xgboost</button></div>' +
                        '<div style="display: inline-block; margin-left: 5px;"><button style="background-color: #0e89dd; border: none; color: white; padding: 6px 12px; cursor: pointer; border-radius: 3px; font-size: 12px;" id="random_forest">Random forest</button></div>' +
                        '<div style="display: inline-block; margin-left: 5px;"><button style="background-color: #0e89dd; border: none; color: white; padding: 6px 12px; cursor: pointer; border-radius: 3px; font-size: 12px;" id="mlp">Red neuronal</button></div>'
                    );
                    const chart = this;   
                    
                    jQuery("#xgboost").off().on("click", function(event) {
                        buttonprediction(chart, tipo, nombre, 'None', 'turbiedad', data, event.target.id);                            
                        jQuery('.custom-button').removeClass('selected').css("background-color", "#0e89dd");
                        jQuery(this).addClass('selected').css("background-color", "#0c76c0");
                    }).mouseover(function() {
                        if (!jQuery(this).hasClass('selected')) {
                            jQuery(this).css("background-color", "#0c76c0");
                        }
                    }).mouseout(function() {
                        if (!jQuery(this).hasClass('selected')) {
                            jQuery(this).css("background-color", "#0e89dd");
                        }
                    })
            
                    jQuery("#random_forest").off().on("click", function(event) {
                        buttonprediction(chart, tipo, nombre, 'None', 'turbiedad', data, event.target.id);                            
                        jQuery('.custom-button').removeClass('selected').css("background-color", "#0e89dd");
                        jQuery(this).addClass('selected').css("background-color", "#0c76c0");
                    }).mouseover(function() {
                        if (!jQuery(this).hasClass('selected')) {
                            jQuery(this).css("background-color", "#0c76c0");
                        }
                    }).mouseout(function() {
                        if (!jQuery(this).hasClass('selected')) {
                            jQuery(this).css("background-color", "#0e89dd");
                        }
                    });
            
                    jQuery("#mlp").off().on("click", function(event) {
                        buttonprediction(chart, tipo, nombre, 'None', 'turbiedad', data, event.target.id);                            
                        jQuery('.custom-button').removeClass('selected').css("background-color", "#0e89dd");
                        jQuery(this).addClass('selected').css("background-color", "#0c76c0");
                    }).mouseover(function() {
                        if (!jQuery(this).hasClass('selected')) {
                            jQuery(this).css("background-color", "#0c76c0");
                        }
                    }).mouseout(function() {
                        if (!jQuery(this).hasClass('selected')) {
                            jQuery(this).css("background-color", "#0e89dd");
                        }
                    });                                             
                }
            }                
        },
        credits: {
            enabled: false
        },

        rangeSelector: {
            enabled: false
        },
        navigation: {
            buttonOptions: {

            }

        },

        title: {
            useHTML: true,
            text: '<i class="far fa-chart-bar"></i> <span>Turbiedad</span><button id="xgboost" class="custom-button" style="margin-left: 10px;">xgboost</button><button id="random_forest" class="custom-button" style="margin-left: 5px;">Random forest</button><button id="mlp" class="custom-button" style="margin-left: 5px;">Red Neuronal</button>',
            style: {

                fontSize: '16px',
            },
            align: 'left',
            x: 0,
            y: 10

        },
        xAxis: {

            type: 'datetime'
        },
        yAxis: {
            opposite: false,
            min: 0,
            title: {

                text: 'Turbiedad [ntu]'
            }
        },
        legend: {
            enabled: true
        },
        plotOptions: {
            area: {
                fillColor: {
                    linearGradient: {
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 1
                    },
                    stops: [
                        [0, '#0e89dd'],
                        [1, Highcharts.color('#0e89dd').setOpacity(0).get('rgba')]
                    ]
                },
                marker: {
                    radius: 2
                },
                lineWidth: 1,
                states: {
                    hover: {
                        lineWidth: 1
                    }
                },
                threshold: null
            }
        },

        series: [{
            type: 'area',
            name: 'Turbiedad [ntu]',
            data: data
        }], 
        /*MODIFICACION YERKO*/
        // exporting: {
        //     buttons: [{
        //         text: 'Predecir',
        //         onclick: function () {
        //         buttonprediction(this, 'pcp', nombre, 'None', 'presiones', data);
        //         },
        //         theme: {
        //             'stroke-width': 1,
        //             stroke: 'silver',
        //             r: 5,
        //             fill: '#6DC8F3',
        //             color: 'white',
        //             states: {
        //                 hover: {
        //                     fill: '#6DC8F3' 
                            
        //                 },
        //                 select: {
        //                     stroke: '#039',
        //                     fill: '#6DC8F3'
        //                 } 
        //             },
        //             style: {
        //                 color: '#FFFFFF',
        //                 //font: 'bold 12px "Trebuchet MS", Verdana, sans-serif',
        //             },
                    

        //         }
        //     }]
        // }
    });

}