import axios from 'axios';
import Highcharts from 'highcharts';
import { chart_cortes, chart_cortes_tipo, chart_cortes_material, chart_container_ranking_cortes, chart_cortes_motivo, chart_container_ranking_of } from './cortes';
import { chart_reclamos_motivo, chart_reclamos, chart_reclamos_canal, chart_reclamos_resultado, chart_container_ranking_reclamos } from './reclamos';
import { chart_container_of, chart_container_ranking_demanda, get_data_chart_cliente_demanda } from './facturacion';
import { moverMapa, functionMapGraph, numero_sector_calidad_str} from './funcionMap';
import { chart_container_reclamos_submotivo_presiones_bajas, chart_container_reclamos_submotivo_presiones_altas, chart_presiones, chart_presiones_afectados } from './presiones';
import { chart_sector_calidad_turbiedad } from './turbiedad';

require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);
var fechaInicio = '';
var nombreLocalidad = '';
var fechaFin = '';
var numeroLocalidad = 0;



export const search_selected = async (location, start, end, tipo, filtro, click, datosClick, graphClick) => {
    if (start !== '' && end !== '') { fechaInicio = start; fechaFin = end; }


    if (click !== undefined) {


        try {
            let numero_cuartel = datosClick.numeroC;
            console.log(numero_cuartel)
            console.log('entrando aca ')
            console.log(filtro)


            if (filtro === 'Presiones') {
                console.log('entro aca')
                get_data_chart_pto_control(location, start, end)
            }else if(filtro === 'Turbiedad'){
                console.log("entré a filtro turbiedad")
                // get_data_chart_pto_control(location, start, end)
                get_data_chart_sector_calidad_turbiedad(location, start, end)
            } else {
                console.log('entrando aca ')
                const datos = await axios.post(`${process.env.REACT_APP_IP_ADDRESS}/search_selected`,
                    { 'location': location }).then((response) => {



                        let tipoDatoA = location.split('(')[1].split(')')[0];

                        let datos = JSON.parse(response.data);
                        //console.log(datos);
                        let latLon = [datos[0].lat, datos[0].lon];


                        if (graphClick !== undefined) {
                            functionMapGraph(latLon, location)
                        } else {
                            if (filtro === 'Resumen') {


                                if (tipoDatoA === 'Cuartel') { get_data_chart_cuartel_todo(numero_cuartel, fechaInicio, fechaFin) }
                                else if (tipoDatoA === 'Cliente') { get_data_chart_cliente_todo(numero_cuartel, fechaInicio, fechaFin) }


                            } else if (filtro === 'Reclamos') {

                                if (tipoDatoA === 'Cuartel') { get_data_chart_cuartel_reclamos(numero_cuartel, fechaInicio, fechaFin) }

                            } else if (filtro === 'Cortes') {

                                if (tipoDatoA === 'Cuartel') { get_data_chart_cuartel_cortes(numero_cuartel, fechaInicio, fechaFin) }

                            } else if (filtro === 'Facturación') {
                                if (tipoDatoA === 'Cuartel') { get_data_chart_cuartel_demanda(numero_cuartel, fechaInicio, fechaFin) }
                                else if (tipoDatoA === 'Cliente') { get_data_chart_cliente_demanda(numero_cuartel, fechaInicio, fechaFin) }

                            }
                            console.log('filtro click ')
                            moverMapa({ cordenadas: latLon, tipo: tipoDatoA, localidad: numero_cuartel, typeEvent: 'click' })
                        }

                    })
                return await datos
            }





        } catch (error) {
            // If any of the awaited promises was rejected, this catch block
            // would catch the rejection reason
            return null;
        }
    } else {
        try {


            if ((location.split('(')[1].split(')')[0] === 'Cliente' && filtro === 'Cortes') ||
                (location.split('(')[1].split(')')[0] === 'Cliente' && filtro === 'Reclamos') ||
                (location.split('(')[1].split(')')[0] === 'Cliente' && filtro === 'Resumen')) {
                location = nombreLocalidad
                document.querySelector('#select2-select2-container').textContent = `${location}`
                document.querySelector('#map-header-text').textContent = `${location}`;
            }

            if (location.split('(')[1].split(')')[0] === 'Cliente' || location.split('(')[1].split(')')[0] === 'Cuartel') {
                // if (location.split('(')[1].split(')')[0] === 'Cuartel') {
                await axios.post(process.env.REACT_APP_IP_ADDRESS + '/search_upper_contain_lower', {
                    location: location.split('(')[0].trim(),
                    tipo: location.split('(')[1].split(')')[0]
                }).then((response) => { let datos = JSON.parse(response.data); nombreLocalidad = `${datos[0].Upper} (${datos[0].Tipo})`; })

            }

            console.log(filtro)
            console.log(nombreLocalidad)
            console.log(location)


            const request_location = await axios.post(`${process.env.REACT_APP_IP_ADDRESS}/search_selected`,
                { 'location': location }).then((response) => {

                    let tipoDatoA = location.split('(')[1].split(')')[0];
                    let datos = JSON.parse(response.data);
                    let nombre_localidad = datos[0].Nombre;
                    let numero_localidad = datos[0].Numero;
                    let latLon = [datos[0].lat, datos[0].lon]
                    
                    moverMapa({ cordenadas: latLon, tipo: tipoDatoA, numeroLocalidad: numero_localidad, localidad: nombre_localidad, typeEvent: 'noclick' })

                    if (numero_localidad !== 0) { numeroLocalidad = numero_localidad; }
                    if (tipoDatoA === 'Localidad') { nombreLocalidad = nombreLocalidad }



                    if (filtro === 'Resumen') {



                        if (tipoDatoA === 'Localidad') { get_data_chart_localidad_todo(nombre_localidad, numeroLocalidad, fechaInicio, fechaFin, tipoDatoA) }
                        else if (tipoDatoA === 'Cuartel') { get_data_chart_cuartel_todo(numero_localidad, fechaInicio, fechaFin) }
                        else if (tipoDatoA === 'Cliente') { get_data_chart_cliente_demanda(numero_localidad, fechaInicio, fechaFin) }


                    } else if (filtro === 'Reclamos') {



                        if (tipoDatoA === 'Localidad') { get_data_chart_localidad_reclamos(nombre_localidad, numeroLocalidad, fechaInicio, fechaFin, tipoDatoA) }
                        else if (tipoDatoA === 'Cuartel') { get_data_chart_cuartel_reclamos(numero_localidad, fechaInicio, fechaFin) }
                        else if (tipoDatoA === 'Cliente') { }


                    } else if (filtro === 'Cortes') {



                        if (tipoDatoA === 'Localidad') { get_data_chart_localidad_cortes(nombre_localidad, numeroLocalidad, fechaInicio, fechaFin, tipoDatoA) }
                        else if (tipoDatoA === 'Cuartel') { get_data_chart_cuartel_cortes(numero_localidad, fechaInicio, fechaFin) }
                        else if (tipoDatoA === 'Cliente') { }

                    } else if (filtro === 'Facturación') {
                        if (tipoDatoA === 'Localidad') { get_data_chart_localidad_of(nombre_localidad, numeroLocalidad, fechaInicio, fechaFin, tipoDatoA) }
                        else if (tipoDatoA === 'Cuartel') { get_data_chart_cuartel_demanda(numero_localidad, fechaInicio, fechaFin) }
                        else if (tipoDatoA === 'Cliente') { get_data_chart_cliente_demanda(numero_localidad, fechaInicio, fechaFin) }


                    } else if (filtro === 'Presiones' && tipoDatoA === 'Localidad') {
                        get_data_chart_localidades_cuarteles_reclamo_submotivo_ranking(nombre_localidad, numeroLocalidad, fechaInicio, fechaFin)
                        get_data_chart_localidades_ptos_control_ranking(nombre_localidad, numeroLocalidad, fechaInicio, fechaFin, latLon, tipoDatoA, filtro)
                    } else if (filtro === 'Seach') {
                        get_data_chart_cliente_demanda(numero_localidad, fechaInicio, fechaFin)
                    } else if (filtro === 'PR14' && tipoDatoA === 'Localidad') {

                        get_data_chart_localidades_ptos_control_ranking(nombre_localidad, numeroLocalidad, fechaInicio, fechaFin, latLon, tipoDatoA, filtro)



                    }else if((filtro === 'Variables Operacionales' && tipoDatoA === 'Localidad')||
                     (filtro === 'Muestras Autocontrol' && tipoDatoA === 'Localidad') || 
                     (filtro === 'Muestras Lodos' && tipoDatoA === 'Localidad')){

                        get_data_chart_localidades_ptos_control_ranking(nombre_localidad, numeroLocalidad, fechaInicio, fechaFin, latLon, tipoDatoA, filtro)

                    } else if (filtro === 'Turbiedad' && tipoDatoA === 'Localidad'){
                        console.log("HICE CLICK EN TURBIEDAD");
                        moverMapa({ cordenadas: latLon, tipo: tipo, localidad: nombre_localidad, typeEvent: 'click', filtro: filtro, numeroLocalidad: numero_localidad })
                        if (numero_sector_calidad_str !== '') {
                            get_data_chart_sector_calidad_turbiedad(numero_sector_calidad_str,fechaInicio, fechaFin)
                          }


                    } 

                })

            // GET information about each book
            return await request_location
        } catch (error) {
            // If any of the awaited promises was rejected, this catch block
            // would catch the rejection reason
            return null;
        }
    }


}




const get_data_chart_cliente_todo = async (numero_cliente, fecha_inicio, fecha_termino) => {
    try {
        // GET a list of book IDs of the current user
        var request_chart_cliente_todo = await axios.post(`${process.env.REACT_APP_IP_ADDRESS}/graph_axios_clientes_todo`,
            {
                'numero_cliente': numero_cliente,
                'fecha_inicio': fecha_inicio, 'fecha_termino': fecha_termino
            }).then((response) => {

                var data_chart = response.data;

                // reclamos, tipo, canal, resultado

                const data_demanda = JSON.parse(data_chart);



                var demanda = data_demanda.Consumo[numero_cliente];
                if (demanda === '0') {
                    demanda = 0;

                }
                let cortes = 0;
                let c_afectados_cortes = 0;
                let reclamos = 0;
                let oferta = 0;

                if (demanda === 0) {
                    chart_container_todo_void('container', '', 'Cliente', '', '');

                } else {
                    chart_container_todo('container', numero_cliente, cortes, c_afectados_cortes, reclamos, oferta, demanda, 'Cliente', fecha_inicio, fecha_termino)

                }


            });

        // GET information about each book
        return await request_chart_cliente_todo
    } catch (error) {
        // If any of the awaited promises was rejected, this catch block
        // would catch the rejection reason
        return null;
    }
}

const get_data_chart_cuartel_todo = async (numero_cuartel, fecha_inicio, fecha_termino) => {
    try {


        // GET a list of book IDs of the current user
        const request_chart_cuartel_todo = await axios.post(`${process.env.REACT_APP_IP_ADDRESS}/graph_axios_cuarteles_todo`,
            {
                'numero_cuartel': numero_cuartel,
                'fecha_inicio': fecha_inicio, 'fecha_termino': fecha_termino
            }).then((response) => {

                console.log(response.data);
                const data_chart = response.data;
                const data_cortes = JSON.parse(data_chart[0]);
                const data_reclamos = JSON.parse(data_chart[1]);
                const data_demanda = JSON.parse(data_chart[2]);


                // map.addLayer(cuarteles_shape);
                // ranking_tipo = "resumen";
                // map_state = "clicked";
                // color_shape_cuarteles (tipo, ranking_tipo, ultimo_boton, map_state, ranking_s,numero_sec);
                //console.log(numero_cuartel);
                numero_cuartel = String(numero_cuartel);
                var cortes = data_cortes.Cortes[numero_cuartel];
                if (cortes === '0') {
                    cortes = 0;
                }
                //console.log(typeof cortes)

                console.log('pase')
                var c_afectados_cortes = data_cortes.C_afectados[numero_cuartel];
                if (c_afectados_cortes === '0') {
                    c_afectados_cortes = 0;
                }

                var reclamos = data_reclamos.Reclamos[numero_cuartel];
                if (reclamos === '0') {
                    reclamos = 0;

                }


                var demanda = data_demanda.Consumo[numero_cuartel];
                if (demanda === '0') {
                    demanda = 0;

                }

                var presion = 0;
                var c_afectados_presion = 0;
                var oferta = 0;





                if (cortes === 0 && c_afectados_cortes === 0 && presion === 0 && c_afectados_presion === 0 && reclamos === 0 && oferta === 0 && demanda === 0) {
                    // console.log(oferta);
                    chart_container_todo_void('container', '', 'Cuartel', '', '');
                    console.log('entre en todo 0')
                } else {


                    chart_container_todo('container', '', cortes, c_afectados_cortes, reclamos, oferta, demanda, 'Cuartel', fecha_inicio, fecha_termino)
                    console.log('entre en otro elemento')
                }


            });

        // GET information about each book
        return await request_chart_cuartel_todo
    } catch (error) {
        // If any of the awaited promises was rejected, this catch block
        // would catch the rejection reason
        return null;
    }
}


const get_data_chart_localidad_todo = async (nombre_localidad, numero_localidad, fecha_inicio, fecha_termino, tipo) => {



    try {
        // GET a list of book IDs of the current user
        const request_chart_localidad_todo = await axios.post(`${process.env.REACT_APP_IP_ADDRESS}/graph_axios_localidades_todo`,
            {
                'nombre_localidad': nombre_localidad, 'numero_localidad': numero_localidad,
                'fecha_inicio': fecha_inicio, 'fecha_termino': fecha_termino
            }).then((response) => {




                let cortes = Number(Object.values(JSON.parse(response.data[0]).Cortes));


                let reclamos = Number(Object.values(JSON.parse(response.data[2]).Reclamos));


                let facturacion = Number(Object.values(JSON.parse(response.data[4]).Consumo));

                let oferta = Number(Object.values(JSON.parse(response.data[3]).Produccion))




                if (cortes === 0 && reclamos === 0 && facturacion === 0 && oferta === 0) {
                    // console.log(oferta);
                    chart_container_todo_void('container', '', tipo, '', '');

                } else {
                    chart_container_todo('container', nombre_localidad, cortes, '', reclamos, oferta, facturacion, tipo, fecha_inicio, fecha_termino)

                }










            });

        // GET information about each book
        return await request_chart_localidad_todo
    } catch (error) {
        // If any of the awaited promises was rejected, this catch block
        // would catch the rejection reason
        return null;
    }
}

const chart_container_todo = (container, nombre, cortes, c_afectados_cortes, reclamos, oferta, demanda, tipo, fecha_inicio_chart, fecha_termino_chart) => {

    if (tipo === 'Localidad') {


        Highcharts.chart(container, {
            chart: {
                zoomType: 'xy'
            },

            credits: {
                enabled: false
            },

            navigation: {
                buttonOptions: {

                }
            },

            title: {
                useHTML: true,
                text: '<i class="far fa-chart-bar"></i> <span>Resumen<span>',
                style: {

                    fontSize: '16px',
                },
                align: 'left',
                x: 0,
                y: 10

            },
            yAxis: {

                type: 'logarithmic',
                minorTickInterval: 'auto',

                title: {
                    text: 'Valores [Log] ',
                },

            },
            xAxis: {
                categories: ['CORTES', 'RECLAMOS', 'FACTURACION', 'PRODUCCION']
            },

            series: [{
                type: 'column',
                name: 'Total',
                data: [{ name: 'Cortes', y: cortes, color: '#0e89dd' }, { name: 'Reclamos', y: reclamos, color: '#0e89dd' },
                { name: 'Facturacion [m³]', y: demanda, color: '#0e89dd' }, { name: 'Producción [m³]', y: oferta, color: '#0e89dd' }],
                dataLabels: {
                    enabled: true,
                    color: '#333',
                    formatter: function () {

                        var value = this.y;
                        // console.log(this.y)

                        if (value === 0.1) {
                            return Highcharts.numberFormat(this.y, 0) + 'S/i';

                        }
                        if (value > 0 && value < 1000) {
                            return Highcharts.numberFormat(this.y, 0);

                        } else if (value > 1000 && value < 1000000) {
                            return Highcharts.numberFormat(this.y / 1000, 1) + 'K';
                        } else if (value > 1000000 && value < 100000000) {
                            return Highcharts.numberFormat(this.y / 1000000, 1) + 'M';
                        }


                        return Highcharts.numberFormat(this.y);
                    },
                    //inside: true
                }

            }]
        });


    } else if (tipo === 'Cuartel') {

        Highcharts.chart(container, {
            chart: {
                zoomType: 'xy'
            },

            credits: {
                enabled: false
            },

            navigation: {
                buttonOptions: {

                }
            },

            title: {
                useHTML: true,
                text: '<i class="far fa-chart-bar"></i> <span>Resumen<span>',
                style: {

                    fontSize: '16px',
                },
                align: 'left',
                x: 0,
                y: 10

            },
            yAxis: {

                type: 'logarithmic',
                minorTickInterval: 'auto',

                title: {
                    text: 'Valores [Log] ',
                },

            },
            xAxis: {
                categories: ['CORTES', 'RECLAMOS', 'FACTURACION']
            },

            series: [{
                type: 'column',
                name: 'Total',
                data: [{ name: 'Cortes', y: cortes, color: '#0e89dd' }, { name: 'Reclamos', y: reclamos, color: '#0e89dd' },
                { name: 'Facturacion [m³]', y: demanda, color: '#0e89dd' }],
                dataLabels: {
                    enabled: true,
                    color: '#333',
                    formatter: function () {

                        var value = this.y;
                        // console.log(this.y)

                        if (value === 0.1) {
                            return Highcharts.numberFormat(this.y, 0) + 'S/i';

                        }
                        if (value > 0 && value < 1000) {
                            return Highcharts.numberFormat(this.y, 0);

                        } else if (value > 1000 && value < 1000000) {
                            return Highcharts.numberFormat(this.y / 1000, 1) + 'K';
                        } else if (value > 1000000 && value < 100000000) {
                            return Highcharts.numberFormat(this.y / 1000000, 1) + 'M';
                        }


                        return Highcharts.numberFormat(this.y);
                    },
                    //inside: true
                }

            }]
        });

    } else if (tipo === 'Cliente') {
        Highcharts.chart(container, {
            chart: {
                zoomType: 'xy'
            },

            credits: {
                enabled: false
            },

            navigation: {
                buttonOptions: {

                }
            },

            title: {
                useHTML: true,
                text: '<i class="far fa-chart-bar"></i> <span>Facturación<span>',
                style: {

                    fontSize: '16px',
                },
                align: 'left',
                x: 0,
                y: 10

            },
            yAxis: {

                type: 'logarithmic',
                minorTickInterval: 'auto',

                title: {
                    text: 'Valor ',
                },

            },
            xAxis: {
                categories: ['FACTURACION']
            },

            series: [{
                type: 'column',
                name: 'Total',
                data: [{ name: 'Facturacion [m³]', y: demanda, color: '#0e89dd' }],
                dataLabels: {
                    enabled: true,
                    color: '#333',
                    formatter: function () {

                        var value = this.y;
                        // console.log(this.y)

                        if (value === 0.1) {
                            return Highcharts.numberFormat(this.y, 0) + 'S/i';

                        }
                        if (value > 0 && value < 1000) {
                            return Highcharts.numberFormat(this.y, 0);

                        } else if (value > 1000 && value < 1000000) {
                            return Highcharts.numberFormat(this.y / 1000, 1) + 'K';
                        } else if (value > 1000000 && value < 100000000) {
                            return Highcharts.numberFormat(this.y / 1000000, 1) + 'M';
                        }


                        return Highcharts.numberFormat(this.y);
                    },
                    //inside: true
                }

            }]
        });

    }


}

const get_data_chart_localidad_cortes = async (nombre_localidad, numero_localidad, fecha_inicio, fecha_termino, tipo) => {


    try {


        // GET a list of book IDs of the current user

        const request_chart = await axios.post(`${process.env.REACT_APP_IP_ADDRESS}/graph_axios_localidades_cortes`,
            {
                'nombre_localidad': nombre_localidad, 'numero_localidad': numero_localidad,
                'fecha_inicio': fecha_inicio, 'fecha_termino': fecha_termino
            }).then((response) => {
                console.log('entre a cortes')

                const data_chart = response.data;
                console.log(data_chart)

                const data_afectados = JSON.parse(data_chart[0]);
                const data_afectados_tipo = JSON.parse(data_chart[1]);
                const data_afectados_material = JSON.parse(data_chart[2]);




                const json_data = data_afectados.C_afectados;


                const result_afectados = [];
                for (let i in json_data) {
                    // console.log(i)
                    // console.log(json_data[i])
                    result_afectados.push([i, json_data[i]]);
                }


                console.log(data_afectados_tipo.Tipo);


                // console.log(result_afectados)

                for (let j in result_afectados)
                    result_afectados[j][0] = parseInt(result_afectados[j][0])

                const json_data_cortes = data_afectados.Cortes;
                const result_cortes = [];

                for (let i in json_data_cortes)
                    result_cortes.push([i, json_data_cortes[i]]);

                for (let j in result_cortes)
                    result_cortes[j][0] = parseInt(result_cortes[j][0])

                const result_tipo = [];

                for (let i in data_afectados_tipo.Tipo)
                    if (data_afectados_tipo.Tipo[i] === '[NULL]') {
                        data_afectados_tipo.Tipo[i] = "NO INFORMADO"
                        result_tipo.push([String(data_afectados_tipo.Tipo[i]), data_afectados_tipo.Cantidad[i]]);

                    } else {
                        result_tipo.push([String(data_afectados_tipo.Tipo[i]), data_afectados_tipo.Cantidad[i]]);

                    }

                const result_material = [];
                for (let i in data_afectados_material.Material)
                    if (data_afectados_material.Material[i] === '[NULL]') {
                        data_afectados_material.Material[i] = "NO INFORMADO"
                        result_material.push([String(data_afectados_material.Material[i]), data_afectados_material.Cantidad[i]]);
                    } else {
                        result_material.push([String(data_afectados_material.Material[i]), data_afectados_material.Cantidad[i]]);

                    }
                console.log(result_material)

                

                chart_cortes_tipo('container2', nombre_localidad, result_tipo, tipo, fecha_inicio, fecha_termino);
                chart_cortes_material('container3', nombre_localidad, result_material, tipo, fecha_inicio, fecha_termino);
                chart_cortes('container1', nombre_localidad, result_cortes, tipo, fecha_inicio, fecha_termino, numeroLocalidad); /*MODIFICACION YERKO*/
                
                get_data_chart_localidades_cuarteles_ranking(nombre_localidad, numero_localidad, fecha_inicio, fecha_termino, tipo, 'Cortes')


            });

        // GET information about each book
        return await request_chart
    } catch (error) {
        // If any of the awaited promises was rejected, this catch block
        // would catch the rejection reason
        console.log(error)
        return null;
    }
}

const get_data_chart_localidad_reclamos = async (nombre_localidad, numero_localidad, fecha_inicio, fecha_termino, tipo) => {

    try {


        // GET a list of book IDs of the current user

        const request_chart_localidad_reclamos = await axios.post(`${process.env.REACT_APP_IP_ADDRESS}/graph_axios_localidades_reclamos`,
            {
                'nombre_localidad': nombre_localidad, 'numero_localidad': numero_localidad,
                'fecha_inicio': fecha_inicio, 'fecha_termino': fecha_termino
            }).then((response) => {
                console.log('reclamos filtro');


                let data_chart = response.data;
                console.log(data_chart)
                // reclamos, tipo, canal, resultado
                let data_reclamos_cantidad = JSON.parse(data_chart[0]);
                let data_reclamos_motivo = JSON.parse(data_chart[1]);
                let data_reclamos_canal = JSON.parse(data_chart[2]);
                let data_reclamos_resultado = JSON.parse(data_chart[3]);

                let json_data_reclamos_cantidad = data_reclamos_cantidad.Reclamos;
                const result_reclamos_cantidad = [];
                for (let i in json_data_reclamos_cantidad)
                    result_reclamos_cantidad.push([i, json_data_reclamos_cantidad[i]]);

                for (let j in result_reclamos_cantidad)
                    result_reclamos_cantidad[j][0] = parseInt(result_reclamos_cantidad[j][0])


                console.log(result_reclamos_cantidad)


                const result_motivo = [];
                for (let i in data_reclamos_motivo.Motivo)
                    if (data_reclamos_motivo.Motivo[i] === '[NULL]') {
                        data_reclamos_motivo.Motivo[i] = "NO INFORMADO"
                        result_motivo.push([data_reclamos_motivo.Motivo[i], data_reclamos_motivo.Cantidad[i]]);

                    } else {
                        result_motivo.push([data_reclamos_motivo.Motivo[i], data_reclamos_motivo.Cantidad[i]]);

                    }


                //esto se puede mejorar con un map por temas de tiempo no lo modifico
                const result_canal = [];
                for (let i in data_reclamos_canal.Canal)
                    if (data_reclamos_canal.Canal[i] === '[NULL]') {
                        data_reclamos_canal.Canal[i] = "NO INFORMADO"
                        result_canal.push([data_reclamos_canal.Canal[i], data_reclamos_canal.Cantidad[i]]);

                    } else {
                        result_canal.push([data_reclamos_canal.Canal[i], data_reclamos_canal.Cantidad[i]]);

                    }

                const result_resultado = [];
                for (let i in data_reclamos_resultado.Resultado)
                    if (data_reclamos_resultado.Resultado[i] === '[NULL]') {
                        data_reclamos_resultado.Resultado[i] = "NO INFORMADO"
                        result_resultado.push([data_reclamos_resultado.Resultado[i], data_reclamos_resultado.Cantidad[i]]);

                    } else {
                        result_resultado.push([data_reclamos_resultado.Resultado[i], data_reclamos_resultado.Cantidad[i]]);

                    }





                chart_reclamos_motivo('container2', nombre_localidad, result_motivo, tipo, fecha_inicio, fecha_termino);
                chart_reclamos_resultado('container4', nombre_localidad, result_resultado, tipo, fecha_inicio, fecha_termino);
                chart_reclamos_canal('container3', nombre_localidad, result_canal, tipo, fecha_inicio, fecha_termino);
                chart_reclamos('container1', nombre_localidad, result_reclamos_cantidad, tipo, fecha_inicio, fecha_termino, numeroLocalidad);
                get_data_chart_localidades_cuarteles_ranking(nombre_localidad, numero_localidad, fecha_inicio, fecha_termino, tipo, 'Reclamos')






            });

        // GET information about each book
        return await request_chart_localidad_reclamos
    } catch (error) {
        // If any of the awaited promises was rejected, this catch block
        // would catch the rejection reason
        return null;
    }
}

const get_data_chart_localidad_of = async (nombre, numero_localidad, fecha_inicio, fecha_termino, tipo) => {
    try {

        // GET a list of book IDs of the current user
        const request_chart_localidad_of = await axios.post(`${process.env.REACT_APP_IP_ADDRESS}/graph_axios_localidades_of`,
            {
                'numero_localidad': numero_localidad,
                'fecha_inicio': fecha_inicio, 'fecha_termino': fecha_termino
            }).then((response) => {
                console.log('of filtro');



                const data_chart = response.data;
                let data_oferta = JSON.parse(data_chart[0]);
                let data_demanda = JSON.parse(data_chart[1]);


                const json_data_oferta = data_oferta.produccion;
                const result_oferta = [];
                for (let i in json_data_oferta)
                    result_oferta.push([i, json_data_oferta[i]]);

                for (let j in result_oferta)
                    result_oferta[j][0] = parseInt(result_oferta[j][0])


                const json_data_demanda = data_demanda.consumo;
                const result_demanda = [];
                for (let i in json_data_demanda)
                    result_demanda.push([i, json_data_demanda[i]]);

                for (let j in result_demanda)
                    result_demanda[j][0] = parseInt(result_demanda[j][0])



                get_data_chart_localidades_cuarteles_ranking(nombre, numero_localidad, fecha_inicio, fecha_termino, tipo, 'Facturación')
                chart_container_of('container1', nombre, result_oferta, result_demanda, 'Localidad', fecha_inicio, fecha_termino, numeroLocalidad);

            });

        // GET information about each book
        return await request_chart_localidad_of
    } catch (error) {
        // If any of the awaited promises was rejected, this catch block
        // would catch the rejection reason
        return null;
    }
}

const get_data_chart_localidades_cuarteles_ranking = async (nombre_localidad, numero_localidad, fecha_inicio, fecha_termino, tipo, filtro) => {

    try {


        // GET a list of book IDs of the current user
        const request_chart_localidades_cuarteles_ranking = await axios.post(`${process.env.REACT_APP_IP_ADDRESS}/graph_axios_localidades_cuarteles_ranking`,
            {
                'nombre_localidad': nombre_localidad, 'numero_localidad': numero_localidad,
                'fecha_inicio': fecha_inicio, 'fecha_termino': fecha_termino
            }).then((response) => {
                // console.log(nombre_localidad)
                // console.log(numero_localidad)
                // console.log(response)

                const data_chart = response.data;
                let data_cortes = JSON.parse(data_chart[0]);
                let data_clientes = JSON.parse(data_chart[1]);
                let data_reclamos = JSON.parse(data_chart[2]);
                let data_demanda = JSON.parse(data_chart[3]);

                const cortes_ranking_c = [];
                for (let i in data_cortes.Cortes)
                    cortes_ranking_c.push([String(data_cortes.Numero_Cuartel[i]), data_cortes.Cortes[i]]);

                const cortes_clientes_ranking_c = [];
                for (let i in data_clientes.C_afectados)
                    cortes_clientes_ranking_c.push([String(data_clientes.Numero_Cuartel[i]), data_clientes.C_afectados[i]]);

                const reclamos_ranking_c = [];
                for (let i in data_reclamos.Reclamos)
                    reclamos_ranking_c.push([String(data_reclamos.Numero_Cuartel[i]), data_reclamos.Reclamos[i]]);

                const demanda_ranking_c = [];
                for (let i in data_reclamos.Reclamos)
                    demanda_ranking_c.push([String(data_demanda.Numero_Cuartel[i]), data_demanda.Consumo[i]]);




                const result_Demanda_top = demanda_ranking_c.slice(0, 5);

                for (let i in result_Demanda_top)
                    result_Demanda_top[i][0] = result_Demanda_top[i][0].toString()


                const result_Reclamos_top = reclamos_ranking_c.slice(0, 5);

                for (let i in result_Reclamos_top)
                    result_Reclamos_top[i][0] = result_Reclamos_top[i][0].toString()


                const result_cortes_top = cortes_ranking_c.slice(0, 5);
                const result_C_afectados_top = cortes_clientes_ranking_c.slice(0, 5);

                for (let i in result_cortes_top)
                    result_cortes_top[i][0] = result_cortes_top[i][0].toString()

                for (let i in result_cortes_top)
                    result_C_afectados_top[i][0] = result_C_afectados_top[i][0].toString()

                if (filtro === 'Cortes') {
                    console.log("entre al filtro");
                    chart_container_ranking_cortes('container', result_cortes_top, tipo, fecha_inicio, fecha_termino)
                } else if (filtro === 'Facturación') {
                    chart_container_ranking_of('container', result_Demanda_top, tipo, fecha_inicio, fecha_termino)
                } else if (filtro === 'Reclamos') {
                    console.log(tipo)
                    chart_container_ranking_reclamos('container', result_Reclamos_top, tipo, fecha_inicio, fecha_termino)
                }



            });

        // GET information about each book
        return await request_chart_localidades_cuarteles_ranking
    } catch (error) {
        // If any of the awaited promises was rejected, this catch block
        // would catch the rejection reason
        return null;
    }
}

const get_data_chart_cuartel_cliente_ranking = async (numero_cuartel, fecha_inicio, fecha_termino, filtro) => {

    try {

        // GET a list of book IDs of the current user
        const request_chart_cuartel_cliente_ranking = await axios.post(`${process.env.REACT_APP_IP_ADDRESS}/graph_axios_cuarteles_clientes_ranking`,
            { 'numero_cuartel': numero_cuartel, 'fecha_inicio': fecha_inicio, 'fecha_termino': fecha_termino }).then((response) => {

                const data_chart = response.data;
                const data_reclamos = JSON.parse(data_chart[0]);
                const data_demanda = JSON.parse(data_chart[1]);

                console.log(data_chart)


                var reclamos_ranking_cli = [];
                for (let i in data_reclamos.Reclamos)
                    reclamos_ranking_cli.push([data_reclamos.Numero_Cliente[i], data_reclamos.Reclamos[i]]);

                const demanda_ranking_cli = [];
                for (let i in data_demanda.Consumo)
                    demanda_ranking_cli.push([data_demanda.Numero_Cliente[i], data_demanda.Consumo[i]]);

                const result_Reclamos_top = reclamos_ranking_cli.slice(0, 5);


                for (let i in result_Reclamos_top)
                    result_Reclamos_top[i][0] = result_Reclamos_top[i][0].toString()


                const result_Demanda_top = demanda_ranking_cli.slice(0, 5);

                for (let i in result_Demanda_top)
                    result_Demanda_top[i][0] = result_Demanda_top[i][0].toString()


                if (filtro === 'Reclamos') {

                    chart_container_ranking_reclamos('container', result_Reclamos_top, 'Cuartel', fecha_inicio, fecha_termino)
                } else if (filtro === 'Facturacion') {
                    chart_container_ranking_demanda('container', result_Demanda_top, 'Cuartel', fecha_inicio, fecha_termino)
                }



            });





        // GET information about each book
        return await request_chart_cuartel_cliente_ranking
    } catch (error) {
        // If any of the awaited promises was rejected, this catch block
        // would catch the rejection reason
        return null;
    }
}

const get_data_chart_cuartel_reclamos = async (numero_cuartel, fecha_inicio, fecha_termino) => {

    try {
        // GET a list of book IDs of the current user
        const request_chart_cuartel_reclamos = await axios.post(`${process.env.REACT_APP_IP_ADDRESS}/graph_axios_cuarteles_reclamos`,
            {
                'numero_cuartel': numero_cuartel,
                'fecha_inicio': fecha_inicio, 'fecha_termino': fecha_termino
            }).then((response) => {
                const data_chart = response.data;

                console.log(data_chart);
                // reclamos, tipo, canal, resultado
                const data_reclamos_cantidad = JSON.parse(data_chart[0]);
                const data_reclamos_motivo = JSON.parse(data_chart[1]);
                const data_reclamos_canal = JSON.parse(data_chart[2]);
                const data_reclamos_resultado = JSON.parse(data_chart[3]);


                const json_data_reclamos_cantidad = data_reclamos_cantidad.Reclamos;

                const result_reclamos_cantidad = [];
                for (let i in json_data_reclamos_cantidad)
                    result_reclamos_cantidad.push([i, json_data_reclamos_cantidad[i]]);

                for (let j in result_reclamos_cantidad)
                    result_reclamos_cantidad[j][0] = parseInt(result_reclamos_cantidad[j][0])


                const result_motivo = [];
                for (let i in data_reclamos_motivo.Motivo)
                    if (data_reclamos_motivo.Motivo[i] === '[NULL]') {
                        data_reclamos_motivo.Motivo[i] = "NO INFORMADO"
                        result_motivo.push([data_reclamos_motivo.Motivo[i], data_reclamos_motivo.Cantidad[i]]);

                    } else {
                        result_motivo.push([data_reclamos_motivo.Motivo[i], data_reclamos_motivo.Cantidad[i]]);

                    }




                const result_canal = [];
                for (let i in data_reclamos_canal.Canal)
                    if (data_reclamos_canal.Canal[i] === '[NULL]') {
                        data_reclamos_canal.Canal[i] = "NO INFORMADO"
                        result_canal.push([data_reclamos_canal.Canal[i], data_reclamos_canal.Cantidad[i]]);

                    } else {
                        result_canal.push([data_reclamos_canal.Canal[i], data_reclamos_canal.Cantidad[i]]);

                    }

                const result_resultado = [];
                for (let i in data_reclamos_resultado.Resultado)
                    if (data_reclamos_resultado.Resultado[i] === '[NULL]') {
                        data_reclamos_resultado.Resultado[i] = "NO INFORMADO"
                        result_resultado.push([data_reclamos_resultado.Resultado[i], data_reclamos_resultado.Cantidad[i]]);

                    } else {
                        result_resultado.push([data_reclamos_resultado.Resultado[i], data_reclamos_resultado.Cantidad[i]]);

                    }

                console.log('pasamos por aca')

                //   var result_Reclamos_top = reclamos_ranking_cli.slice(0,5);

                //   for(let i in result_Reclamos_top)
                //      result_Reclamos_top[i][0] = result_Reclamos_top[i][0].toString()



                //   chart_container_ranking_reclamos(numero_cuartel, result_Reclamos_top, '', fecha_inicio, fecha_termino)


                chart_reclamos('container1', numero_cuartel, result_reclamos_cantidad, 'Cuartel', fecha_inicio, fecha_termino);
                chart_reclamos_motivo('container2', numero_cuartel, result_motivo, 'Cuartel', fecha_inicio, fecha_termino);
                chart_reclamos_canal('container3', numero_cuartel, result_canal, 'Cuartel', fecha_inicio, fecha_termino);
                chart_reclamos_resultado('container4', numero_cuartel, result_resultado, 'Cuartel', fecha_inicio, fecha_termino);
                get_data_chart_cuartel_cliente_ranking(numero_cuartel, fecha_inicio, fecha_termino, 'Reclamos');



            });

        // GET information about each book
        return await request_chart_cuartel_reclamos
    } catch (error) {
        // If any of the awaited promises was rejected, this catch block
        // would catch the rejection reason
        return null;
    }
}

const get_data_chart_cuartel_demanda = async (numero_cuartel, fecha_inicio, fecha_termino) => {

    try {
        console.log(numero_cuartel)

        // GET a list of book IDs of the current user
        var request_chart_cuartel_demanda = await axios.post(`${process.env.REACT_APP_IP_ADDRESS}/graph_axios_cuarteles_demanda`,
            {
                'numero_cuartel': numero_cuartel,
                'fecha_inicio': fecha_inicio, 'fecha_termino': fecha_termino
            }).then((response) => {

                const data_chart = response.data;
                const data_demanda = JSON.parse(data_chart);


                const json_data_demanda = data_demanda.consumo;
                const result_demanda = [];
                for (let i in json_data_demanda)
                    result_demanda.push([i, json_data_demanda[i]]);

                for (let j in result_demanda)
                    result_demanda[j][0] = parseInt(result_demanda[j][0])

                const result_oferta = 0;


                // var result_Demanda_top = demanda_ranking_cli.slice(0,5);

                // for(var i in result_Demanda_top)
                //    result_Demanda_top[i][0] = result_Demanda_top[i][0].toString()


                // chart_container_ranking_demanda(numero_cuartel, result_Demanda_top, tipo, fecha_inicio, fecha_termino)

                chart_container_of('container1', numero_cuartel, result_oferta, result_demanda, 'Cuartel', fecha_inicio, fecha_termino);
                get_data_chart_cuartel_cliente_ranking(numero_cuartel, fecha_inicio, fecha_termino, 'Facturacion');
            });

        // GET information about each book
        return await request_chart_cuartel_demanda
    } catch (error) {
        // If any of the awaited promises was rejected, this catch block
        // would catch the rejection reason
        return null;
    }
}

const get_data_chart_cuartel_cortes = async (numero_cuartel, fecha_inicio, fecha_termino) => {
    try {

        // GET a list of book IDs of the current user
        const request_chart_cuarteles = await axios.post(`${process.env.REACT_APP_IP_ADDRESS}/graph_axios_cuarteles_cortes`,
            {
                'numero_cuartel': numero_cuartel,
                'fecha_inicio': fecha_inicio, 'fecha_termino': fecha_termino
            }).then((response) => {


                const data_chart = response.data;

                const data_afectados = JSON.parse(data_chart[0]);
                const data_afectados_tipo = JSON.parse(data_chart[1]);
                const data_afectados_material = JSON.parse(data_chart[2]);
                const data_afectados_motivo = JSON.parse(data_chart[3]);



                const json_data = data_afectados.C_afectados;
                const result_afectados = [];
                for (let i in json_data)
                    result_afectados.push([i, json_data[i]]);

                for (let j in result_afectados)
                    result_afectados[j][0] = parseInt(result_afectados[j][0])

                const json_data_cortes = data_afectados.Cortes;
                const result_cortes = [];
                for (let i in json_data_cortes)
                    result_cortes.push([i, json_data_cortes[i]]);
                for (let j in result_cortes)
                    result_cortes[j][0] = parseInt(result_cortes[j][0])

                const result_tipo = [];
                for (let i in data_afectados_tipo.Tipo)
                    if (data_afectados_tipo.Tipo[i] === '[NULL]') {
                        data_afectados_tipo.Tipo[i] = "NO INFORMADO"
                        result_tipo.push([data_afectados_tipo.Tipo[i], data_afectados_tipo.Cantidad[i]]);

                    } else {
                        result_tipo.push([data_afectados_tipo.Tipo[i], data_afectados_tipo.Cantidad[i]]);

                    }

                const result_material = [];
                for (let i in data_afectados_material.Material)
                    if (data_afectados_material.Material[i] === '[NULL]') {
                        data_afectados_material.Material[i] = "NO INFORMADO"
                        result_material.push([data_afectados_material.Material[i], data_afectados_material.Cantidad[i]]);
                    } else {
                        result_material.push([data_afectados_material.Material[i], data_afectados_material.Cantidad[i]]);

                    }

                const result_motivo = [];
                for (let i in data_afectados_motivo.Motivo)
                    if (data_afectados_motivo.Motivo[i] === '[NULL]') {
                        data_afectados_motivo.Motivo[i] = "NO INFORMADO"
                        result_motivo.push([data_afectados_motivo.Motivo[i], data_afectados_motivo.Cantidad[i]]);
                    } else {
                        result_motivo.push([data_afectados_motivo.Motivo[i], data_afectados_motivo.Cantidad[i]]);

                    }

                chart_cortes('container', numero_cuartel, result_cortes, 'Cuartel', fecha_inicio, fecha_termino);
                chart_cortes_tipo('container1', numero_cuartel, result_tipo, 'Cuartel', fecha_inicio, fecha_termino);
                chart_cortes_motivo('container2', numero_cuartel, result_motivo, 'Cuartel', fecha_inicio, fecha_termino);
                chart_cortes_material('container3', numero_cuartel, result_material, 'Cuartel', fecha_inicio, fecha_termino);

            });

        // GET information about each book
        return await request_chart_cuarteles
    } catch (error) {
        // If any of the awaited promises was rejected, this catch block
        // would catch the rejection reason
        return null;
    }
}


const chart_container_todo_void = (container, nombre, tipo, fecha_inicio, fecha_termino) => {

    if (tipo === 'Localidad') {

        Highcharts.chart(container, {
            chart: {
                zoomType: 'xy'
            },

            credits: {
                enabled: false
            },

            navigation: {
                buttonOptions: {

                }
            },

            title: {
                useHTML: true,
                text: '<i class="far fa-chart-bar"></i> <span>Resumen<span>',
                style: {

                    fontSize: '16px',
                },
                align: 'left',
                x: 0,
                y: 10

            },
            yAxis: {
                opposite: false,
                min: 0,

            },
            xAxis: {
                categories: ['CORTES', 'RECLAMOS', 'FACTUACION', 'PRODUCCION']
            },

            series: [{
                type: 'column',
                name: 'Cortes',
                data: [0, 0, 0, 0],
                dataLabels: {
                    enabled: true,
                    color: '#333',
                    //inside: true
                }

            }]
        });
    } else if (tipo === 'Cuartel') {

        Highcharts.chart(container, {
            chart: {
                zoomType: 'xy'
            },

            credits: {
                enabled: false
            },

            navigation: {
                buttonOptions: {

                }
            },

            title: {
                useHTML: true,
                text: '<i class="far fa-chart-bar"></i> <span>Resumen<span>',
                style: {

                    fontSize: '16px',
                },
                align: 'left',
                x: 0,
                y: 10

            },
            yAxis: {
                opposite: false,
                min: 0,

            },
            xAxis: {
                categories: ['CORTES', 'RECLAMOS', 'FACTUACION']
            },

            series: [{
                type: 'column',
                name: 'Cortes',
                data: [0, 0, 0],
                dataLabels: {
                    enabled: true,
                    color: '#333',
                    //inside: true
                }

            }]
        });
    } else if (tipo === 'Cliente') {

        Highcharts.chart(container, {
            chart: {
                zoomType: 'xy'
            },

            credits: {
                enabled: false
            },

            navigation: {
                buttonOptions: {

                }
            },

            title: {
                useHTML: true,
                text: '<i class="far fa-chart-bar"></i> <span>Resumen<span>',
                style: {

                    fontSize: '16px',
                },
                align: 'left',
                x: 0,
                y: 10

            },
            yAxis: {
                opposite: false,
                min: 0,

            },
            xAxis: {
                categories: ['FACTUACION']
            },

            series: [{
                type: 'column',
                name: 'Cortes',
                data: [0],
                dataLabels: {
                    enabled: true,
                    color: '#333',
                    //inside: true
                }

            }]
        });
    }

}

//presiones funciones 

const get_data_chart_localidades_cuarteles_reclamo_submotivo_ranking = async (nombre_localidad, numero_localidad, fecha_inicio, fecha_termino) => {

    try {


        // GET a list of book IDs of the current user
        const request_chart_localidades_cuarteles_ranking = await axios.post(`${process.env.REACT_APP_IP_ADDRESS}/graph_axios_localidades_cuarteles_reclamo_submotivo_ranking`,
            {
                'nombre_localidad': nombre_localidad, 'numero_localidad': numero_localidad,
                'fecha_inicio': fecha_inicio, 'fecha_termino': fecha_termino
            }).then((response) => {

                var data_chart = response.data;
                console.log(data_chart)
                let data_reclamos_bajas = JSON.parse(data_chart[0]);
                console.log(data_reclamos_bajas)
                let data_reclamos_altas = JSON.parse(data_chart[1]);
                console.log(data_reclamos_altas)

                const reclamos_bajas_ranking_c = [];
                for (let i in data_reclamos_bajas.Reclamos_bajas)
                    reclamos_bajas_ranking_c.push([data_reclamos_bajas.Numero_Cuartel[i], data_reclamos_bajas.Reclamos_bajas[i]]);

                const reclamos_altas_ranking_c = [];
                for (let i in data_reclamos_altas.Reclamos_altas)
                    reclamos_altas_ranking_c.push([data_reclamos_altas.Numero_Cuartel[i], data_reclamos_altas.Reclamos_altas[i]]);


                const result_Reclamos_bajas_top = reclamos_bajas_ranking_c.slice(0, 5);

                for (let i in result_Reclamos_bajas_top)
                    result_Reclamos_bajas_top[i][0] = result_Reclamos_bajas_top[i][0].toString()


                const result_Reclamos_altas_top = reclamos_altas_ranking_c.slice(0, 5);

                for (var i in result_Reclamos_altas_top)
                    result_Reclamos_altas_top[i][0] = result_Reclamos_altas_top[i][0].toString()

                chart_container_reclamos_submotivo_presiones_bajas('container', nombre_localidad, result_Reclamos_bajas_top, 'Localidad', fecha_inicio, fecha_termino);
                chart_container_reclamos_submotivo_presiones_altas('container1', nombre_localidad, result_Reclamos_altas_top, 'Localidad', fecha_inicio, fecha_termino)

            });

        // GET information about each book
        return await request_chart_localidades_cuarteles_ranking
    } catch (error) {
        // If any of the awaited promises was rejected, this catch block
        // would catch the rejection reason
        return null;
    }
}

const get_data_chart_localidades_ptos_control_ranking = async (nombre_localidad, numero_localidad, fecha_inicio, fecha_termino, cordenadas, tipo, filtro) => {

    try {

        // GET a list of book IDs of the current user
        var request_chart = await axios.post(`${process.env.REACT_APP_IP_ADDRESS}/graph_axios_localidades_pts_control_ranking`,
            {
                'nombre_localidad': nombre_localidad, 'numero_localidad': numero_localidad,
                'fecha_inicio': fecha_inicio, 'fecha_termino': fecha_termino
            }).then((response) => {

                const data_chart = response.data;
                let data_presion = JSON.parse(data_chart);
                // console.log(data_chart)
                const presion_ranking_p = [];
                for (let i in data_presion.Presion)
                    presion_ranking_p.push([parseInt(data_presion.Pto_Control[i]), data_presion.Presion[i]]);

                // map_state = 'clicked';
                // color_shape_cuarteles(tipo, ranking_tipo, ultimo_boton, map_state, ranking_c, numero_loc);
                // color_shape_sectores_presion(tipo, ranking_tipo, ultimo_boton, map_state, ranking_c, numero_loc)
                // color_shape_pto_control(tipo, ranking_tipo, ultimo_boton, map_state, presion_ranking_p, numero_loc)

                // map.removeLayer(cuarteles_shape);
                // map.removeLayer(sectores_shape);
                moverMapa({ cordenadas: cordenadas, tipo: tipo, localidad: nombre_localidad, typeEvent: 'click', filtro: filtro, numeroLocalidad: numero_localidad })
            });

        // GET information about each book
        return await request_chart
    } catch (error) {
        // If any of the awaited promises was rejected, this catch block
        // would catch the rejection reason
        return null;
    }
}

const get_data_chart_pto_control = async (numero_pto_control, fecha_inicio, fecha_termino) => {

    try {


        // GET a list of book IDs of the current user
        const request_chart = await axios.post(`${process.env.REACT_APP_IP_ADDRESS}/graph_axios_pto_control`,
            {
                'numero_pto_control': numero_pto_control,
                'fecha_inicio': fecha_inicio, 'fecha_termino': fecha_termino
            }).then((response) => {
                const data_chart = response.data;
                // console.log(data_chart)
                // reclamos, tipo, canal, resultado
                const data_afectados = JSON.parse(data_chart[0])
                let json_data = data_afectados.C_afectados;
                const result_afectados = [];
                for (let i in json_data)
                    result_afectados.push([i, json_data[i]]);

                for (let j in result_afectados)
                    result_afectados[j][0] = parseInt(result_afectados[j][0])

                const json_data_presion = data_afectados.Presion;
                const result_presiones = [];
                for (let i in json_data_presion)
                    result_presiones.push([i, json_data_presion[i]]);
                for (let j in result_presiones)
                    result_presiones[j][0] = parseInt(result_presiones[j][0])



                chart_presiones('container', numero_pto_control, result_presiones, '', fecha_inicio, fecha_termino);
                chart_presiones_afectados('container1', numero_pto_control, result_afectados, '', fecha_inicio, fecha_termino);
            });

        // GET information about each book
        return await request_chart
    } catch (error) {
        // If any of the awaited promises was rejected, this catch block
        // would catch the rejection reason
        return null;
    }
}

const get_data_chart_sector_calidad_turbiedad = async (numero_sector_calidad, fecha_inicio, fecha_termino) => {

    try {


        // GET a list of book IDs of the current user
        const request_chart = await axios.post(`${process.env.REACT_APP_IP_ADDRESS}/graph_axios_sector_calidad`,
            {
                'numero_sector_calidad': numero_sector_calidad,
                'fecha_inicio': fecha_inicio, 'fecha_termino': fecha_termino
            }).then((response) => {
                const data_chart = response.data;
                // reclamos, tipo, canal, resultado
                const data_turbiedad = JSON.parse(data_chart)
                console.log(data_turbiedad)

                let json_data = data_turbiedad.Turbiedad;
                const result_turbiedad = [];
                for (let i in json_data)
                result_turbiedad.push([parseInt(i), json_data[i]]);
                console.log(result_turbiedad)
                var element = document.getElementById("container");

                element.classList.remove('no-display');


                chart_sector_calidad_turbiedad('container', numero_sector_calidad, result_turbiedad, 'sector_calidad', fecha_inicio, fecha_termino);
            });

        // GET information about each book
        return await request_chart
    } catch (error) {
        // If any of the awaited promises was rejected, this catch block
        // would catch the rejection reason
        return null;
    }
}


//presiones funciones 

/*Modificacion Yerko*/
export const graph_data_line_fixer = (response) =>{
    /*Asume que primero va el valor, segundo tiempo, tercero desviacion estandar*/
    try{
        let arrayLine = [];
        let arrayRange = [];
        let lineDict = Object.values(response)[0];
        let rangeDict = Object.values(response)[1];
        console.log(response);
        for (let i = 0; i < Object.values(lineDict)[0].length; i++){
            let maxValue = Object.values(lineDict)[0][i] + Object.values(rangeDict)[0][i];
            let minValue = Object.values(lineDict)[0][i] - Object.values(rangeDict)[0][i];
            //console.log([Object.values(response)[1][i], Math.round(Object.values(response)[0][i])])
            arrayLine.push([Object.values(lineDict)[1][i], parseFloat(Object.values(lineDict)[0][i].toFixed(2))]);
            arrayRange.push([Object.values(rangeDict)[1][i], parseFloat(Math.max(minValue, 0).toFixed(2)), parseFloat(maxValue.toFixed(2))]);
            
        }

        arrayLine.forEach((par_datos) =>{
            par_datos[0] = par_datos[0] * 1000;
        });

        arrayRange.forEach((par_datos) =>{
            par_datos[0] = par_datos[0] * 1000;
        });

        
        console.log(arrayLine);
        return {'arrayLine': arrayLine, 'arrayRange': arrayRange}

    } catch (error) {
        console.error("FALLO LA LIMPIEZA")
        console.log(error);
    // If any of the awaited promises was rejected, this catch block
    // would catch the rejection reason
    return null;
    }

}
export const graph_create_series = (filtro, modelo) => {
    let serie = {    
                name: 'Predicción ' + filtro + ' (' + modelo + ')',
                type: 'line',
                zIndex: 1,
                color: Highcharts.getOptions().colors[1],
            };
    let range = {
                name: 'Range',
                type:'arearange',
                lineWidth: 0,
                linkedTo: ':previous',
                color: Highcharts.getOptions().colors[1],
                fillOpacity: 0.3,
                zIndex: 0,
                marker: {
                enabled: false
            }
        };
    return {'serie':serie, 'range':range}
    }
