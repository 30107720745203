import React from 'react'

const ButtonOptions = () => {
const username = localStorage.getItem('username');
const logoutAccion=()=> 
{
    localStorage.removeItem('token');
    window.location.reload()
}

    return (
        <div className="dropdown">
            <button className="btn btn-secondary dropdown-toggle option-style" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
            <i className="fa-solid fa-circle-user"></i> Hola {username}
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
             
                <li><a className="dropdown-item"  onClick={logoutAccion} href="#">Logout</a></li>
         
       
            </ul>
        </div>
    )
}

export default ButtonOptions