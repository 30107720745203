import $ from 'jquery'
import Highcharts from 'highcharts';
import { search_selected } from './utils';
import { } from "mapbox-gl-leaflet";
import { } from "mapbox-gl";
import { } from "leaflet-zoombox";
import { } from "leaflet-easybutton";
import axios from 'axios';
import L from 'leaflet';

import { datoJson, pcpJson, sectorJson, sectorCalidadJson } from './shapesGeoJson';
import { Area_urbana, EjesCalles, Napas, Tipo_suelo, ConduccionAP, CUARTEL, Grifos, PCP, RedAP, ReductoraPresion, SECTOR_CALIDAD, SECTOR_PRESION, SERVICIO_AP, Valvulas, Camaras, ConduccionAS, Flujo, PEAS, SECTOR_AS, Antigolpe_Ariete, atraviesos, macromedidores, Recintos, Servidumbres, RedAS, Vertederos_Tormenta } from './shapesGeoJsonExtra';

const geoJsonFiles = {
    "Área Urbana": Area_urbana, 
    "Ejes Calles": EjesCalles, 
    "Napas": Napas, 
    "Tipo Suelo": Tipo_suelo, 
    "Conducción AP": ConduccionAP, 
    "Cuartel": CUARTEL, 
    "Grifos": Grifos, 
    "PCP": PCP, 
    "Red AP": RedAP, 
    "Reductora Presión": ReductoraPresion, 
    "Sector Calidad": SECTOR_CALIDAD, 
    "Sector Presión": SECTOR_PRESION, 
    "Servicio AP": SERVICIO_AP, 
    "Válvulas": Valvulas, 
    "Cámaras": Camaras, 
    "Conducción AS": ConduccionAS, 
    "Flujo": Flujo, 
    "PEAS": PEAS, 
    "Sector AS": SECTOR_AS, 
    "Red AS": RedAS,
    "Vertederos Tormenta" : Vertederos_Tormenta,
    "Antigolpe Ariete": Antigolpe_Ariete, 
    "Atraviesos": atraviesos, 
    "Macromedidores": macromedidores, 
    "Recintos": Recintos, 
    "Servidumbres": Servidumbres,
};

// Colors for each layer in HEX.
const layerColors = {
    "Área Urbana": '#FF0000', 
    "Ejes Calles": '#0000FF', 
    "Napas": '#008000', 
    "Tipo Suelo": '#FFFF00', 
    "Conducción AP": '#800080', 
    "Cuartel": '#FFA500', 
    "Grifos": '#FFC0CB', 
    "PCP": '#00FFFF',
    "Red AP": '#67C7F2', 
    "Reductora Presión": '#808080', 
    "Sector Calidad": '#000000', 
    "Sector Presión": '#00FF00', 
    "Servicio AP": '#8B0000', 
    "Válvulas": '#DCBA38', 
    "Cámaras": '#FF0000', 
    "Conducción AS": '#F08080', 
    "Flujo": '#ADFF2F', 
    "PEAS": '#FFD700', 
    "Sector AS": '#DAA520',
    "Red AS": '#BC8F8F',
    "Vertederos Tormenta" : '#7B68EE',
    "Antigolpe Ariete": '#9ACD32', 
    "Atraviesos": '#4169E1', 
    "Macromedidores": '#BDB76B', 
    "Recintos": '#2E8B57', 
    "Servidumbres": '#6A5ACD',
};


const styles = {
    Point: {radius: 5, color: 'red'},
    LineString: {color: 'blue'},
    Polygon: {color: 'green'},
    MultiPolygon: {color: 'green'}
};


var categoria;
var numero_loc;
var cod_planta;
var contador;
var red_layer = window.L.geoJSON();
var sector_shape = window.L.geoJSON();
var sector_calidad_shape = window.L.geoJSON();
var pts_control_shape = window.L.geoJSON();
var pts_control_shape_pr14 = window.L.geoJSON();
var pts_control_shape_pr23 = window.L.geoJSON();
var cliente_layer = window.L.geoJSON();
var corte_layer = window.L.geoJSON();
var reclamo_layer = window.L.geoJSON();
var layoutNew = window.L.geoJSON();
var theMarker;
var highlightStyle = {
    weight: 5,
    color: "white",
    fillOpacity: 0.5
};
var highlightStyleOut = {
    weight: 0,
    fillOpacity: 0
};
export var numero_sector_calidad_str = '';



// variables para el pr14

var timeStart;
var timeEnd;
var uNorte;
var uEste;


export const LoadMap = () => {
    $(document).ready(function () {

        var southWest = window.L.latLng(-53.15483, -70.91129),
            northEast = window.L.latLng(-41.4693, -72.9428900),
            bounds = window.L.latLngBounds(southWest, northEast);


        map = window.L.map('map', {
            attributionControl: false,
            center: [-45.5841, -72.0602],
            minZoom: 7.5,
            maxBounds: bounds,
            zoom: 11.1,
            layers: [basemap],
            urlHash: true,
            zoomSnap: 0.3,
            wheelDebounceTime: 40,
            wheelPxPerZoomLevel: 140,
        });



        var options = {
            modal: true,
            title: "Box area zoom"
        };

        let overlayMaps = {};

        for (let key in geoJsonFiles) {
            let layer = processGeoJsonLayer(geoJsonFiles[key], key);
            overlayMaps[key] = layer;
        }
        
        function processGeoJsonLayer(geoJsonData, layerName) {
            let layer;
            if (geoJsonData.features[0].geometry.type === 'Point') {
                layer = L.geoJson(geoJsonData, {
                    pointToLayer: function (feature, latlng) {
                        // return L.circleMarker(latlng, styles[feature.geometry.type]);
                        return L.circleMarker(latlng, { radius: 5, color: layerColors[layerName] }); // use layer color

                    },
                    onEachFeature: onEachFeature
                });
            } else {
                layer = L.geoJson(geoJsonData, {
                    // style: styles[geoJsonData.features[0].geometry.type],
                    style: { color: layerColors[layerName] }, // use layer color

                    onEachFeature: onEachFeature
                });
            }
            return layer;
        }
        
        function onEachFeature(feature, layer) {
            layer.on({
                click: function (e) {
                    let properties = e.target.feature.properties;
                    let info = '';
                    for (let prop in properties) {
                        info += `${prop}: ${properties[prop]}<br>`;
                    }
                    layer.bindPopup(info).openPopup();
                }
            });
        }
        
        var control = window.L.control.zoomBox(options);
        map.addControl(control);

        window.L.easyButton('fa-undo', function () {
            map.setView([-45.5841, -72.0602], 11.1);
        }).addTo(map);

        
        // Add overlays to layers control
        window.L.control.layers({}, overlayMaps).addTo(map);
        
    })
}
var map;
var cuarteles_style = {
    "background-color": "rgba(0, 102, 240, 1)",
    "color": "rgba(0, 102, 240, 1)",
    "weight": "0.3",
    "fillOpacity": "0"
};
var cuarteles_style_select = {
    "background-color": "rgba(1, 102, 240, 1)",
    "color": "rgba(0, 102, 240, 1)",
    "weight": "0.3",
    "fillOpacity": "0.2"
};
var basemap = window.L.mapboxGL({
    attribution: "",
    style: 'https://api.maptiler.com/maps/basic/style.json?key=zrN5ZqN9pFOBGB5QeGDW'

});


//Funcion interaciones con el mapa, click funcion

//Funcion interaciones con el mapa, click funcion

const draw_chart_cuartel = (e) => {

    const currentLayer = e.target;
    const numero_cuartel = currentLayer.feature.properties.cod_cuart;
    const numero_cuartel_str = numero_cuartel.toString();
    const rut_empresa = currentLayer.feature.properties.rut;
    const fecha_inicio = $('#picker').data('daterangepicker').startDate.format("YYYY-MM-DD");
    const fecha_termino = $('#picker').data('daterangepicker').endDate.format("YYYY-MM-DD");


    document.querySelector('#select2-select2-container').textContent = `${numero_cuartel_str} (Cuartel)`;
    document.querySelector('#map-header-text').textContent = `${numero_cuartel_str} (Cuartel)`;


    const element1 = document.getElementById("container1");
    const element2 = document.getElementById("container2");
    const element3 = document.getElementById("container3");
    const element4 = document.getElementById("container4");
    const element5 = document.getElementById("container5");
    element1.classList.add('no-display');
    element2.classList.add('no-display');
    element3.classList.add('no-display');
    element4.classList.add('no-display');
    element5.classList.add('no-display');
    document.getElementById('title').innerHTML = 'Resumen';
    document.getElementById('subtitle').innerHTML = `Menú &gt; Resumen`;
    document.getElementById('plot-header-ind').innerHTML = 'Resumen';
    numero_loc = currentLayer.feature.properties.cod_loc;
    search_selected(`${numero_cuartel_str} (Cuartel)`, fecha_inicio, fecha_termino, 'Cuartel', 'Resumen', true, { numeroC: numero_cuartel_str, rutEmpresa: rut_empresa })




}
const draw_chart_cliente = (e) => {

    var currentLayer = e.target;
    var numero_cliente = currentLayer.feature.properties.N_cliente;
    var numero_cliente_str = numero_cliente.toString();

    var fecha_inicio = $('#picker').data('daterangepicker').startDate.format("YYYY-MM-DD");
    var fecha_termino = $('#picker').data('daterangepicker').endDate.format("YYYY-MM-DD");



    document.querySelector('#map-header-text').textContent = `${numero_cliente_str} (Cliente)`;
    document.querySelector('#select2-select2-container').textContent = `${numero_cliente_str} (Cliente)`;

    const element1 = document.getElementById("container1");
    const element2 = document.getElementById("container2");
    const element3 = document.getElementById("container3");
    const element4 = document.getElementById("container4");
    const element5 = document.getElementById("container5");
    element1.classList.add('no-display');
    element2.classList.add('no-display');
    element3.classList.add('no-display');
    element4.classList.add('no-display');
    element5.classList.add('no-display');
    document.getElementById('title').innerHTML = 'Resumen';
    document.getElementById('subtitle').innerHTML = `Menú &gt; Resumen`;
    document.getElementById('plot-header-ind').innerHTML = 'Resumen';


    search_selected(`${numero_cliente_str} (Cliente)`, fecha_inicio, fecha_termino, 'Cliente', 'Facturación', true, { numeroC: numero_cliente_str, })

    console.log(currentLayer)
}
//--------------------.---------------------------
const resetHighlightPresionesShape = (e) => {
    var currentLayer = e.target;
    pts_control_shape.resetStyle(currentLayer);
}
const resetHighlightCuartelesShape = (e) => {
    var currentLayer = e.target;
    layoutNew.resetStyle(currentLayer);
}
const highlightFeatureCuartelesShape = (e) => {


    var currentLayer = e.target;



    if (currentLayer.feature.properties.cod_loc === numero_loc) {

        currentLayer.setStyle(highlightStyle);
        currentLayer.bringToFront();


    } else {

        currentLayer.setStyle(highlightStyleOut);

    }

}
const draw_chart_pto_presion = (e) => {

    let currentLayer = e.target;
    let numero_pto = currentLayer.feature.properties.cod_pcp;
    // let direccion = currentLayer.feature.properties.direccion;

    let numero_pto_str = numero_pto.toString();

    let fecha_inicio = $('#picker').data('daterangepicker').startDate.format("YYYY-MM-DD");
    let fecha_termino = $('#picker').data('daterangepicker').endDate.format("YYYY-MM-DD");

    // let fecha_inicio_chart = $('#picker').data('daterangepicker').startDate.format("DD MMMM, YYYY");
    // let fecha_termino_chart = $('#picker').data('daterangepicker').endDate.format("DD MMMM, YYYY");


    search_selected(numero_pto_str, fecha_inicio, fecha_termino, 'Localidad', 'Presiones', true, { numeroC: '' })


}

const draw_chart_sector_calidad = (e) => {

    const currentLayer = e.target;
    const sector_calidad = currentLayer.feature.properties.sector_calidad;
    const rut_empresa = currentLayer.feature.properties.rut;
    numero_sector_calidad_str = sector_calidad.toString();
    const fecha_inicio = $('#picker').data('daterangepicker').startDate.format("YYYY-MM-DD");
    const fecha_termino = $('#picker').data('daterangepicker').endDate.format("YYYY-MM-DD");


    // document.querySelector('#select2-select2-container').textContent = `${numero_sector_calidad_str} (SectorCalidad)`;
    // document.querySelector('#map-header-text').textContent = `${numero_sector_calidad_str} (SectorCalidad)`;


    numero_loc = currentLayer.feature.properties.cod_loc;
    console.log(numero_sector_calidad_str)
    search_selected(numero_sector_calidad_str, fecha_inicio, fecha_termino, 'Sector_Calidad', 'Turbiedad', true, { numeroC: '' })

}

// Funciones principales para PR14 Y PR23


const draw_chart_pr14 = (e) => {


    if (contador !== undefined) {
        var table = $('#dataTable').DataTable();
        table.destroy();


    } else {
        contador = contador + 1;
    }
    document.getElementById('container7').classList.add('no-display');







    let currentLayer = e.target;
    let fecha_inicio = $('#picker').data('daterangepicker').startDate.format("YYYY-MM-DD");
    let fecha_termino = $('#picker').data('daterangepicker').endDate.format("YYYY-MM-DD");
    let UTM_ESTE = currentLayer.feature.properties.UTM_ESTE;
    let UTM_NORTE = currentLayer.feature.properties.UTM_NORTE;


    console.log('UTM_ESTE:' + UTM_ESTE)
    console.log('UTM_NORTE:' + UTM_NORTE)



    timeStart = fecha_inicio;
    timeEnd = fecha_termino;
    uNorte = UTM_NORTE;
    uEste = UTM_ESTE;

    dataTable({ UTM_ESTE: UTM_ESTE, UTM_NORTE: UTM_NORTE, fechaInicio: fecha_inicio, fechaTermino: fecha_termino, filtro: 'PR14' });


}

    // Mapeo de nombres a thresholds
    const thresholds = {
        "TURBIEDAD": [-Infinity, 4], // Permitido de -infinito a 1
        "CLORO LIBR": [0.2, 2], 
        "CT": [-Infinity, 2], // Permitido de -infinito a 2
        "E-COLI": [-Infinity,1],
        "F-": [-Infinity, 1.5],
        "Fe": [-Infinity, 0.3],
        "PH": [6.5, 8.5],
        "TETRACLORO": [-Infinity, 40],
        "TRICLOROME": [-Infinity, 0.2],
        "TRC": [40, 60],
        "H LOD": [75,Infinity]
    };

    

    
    const createDataTable = ({ header, rows }) => {
        const trFuncion = document.getElementById("trFuncion");
        const bodyFuncion = document.getElementById("bodyFuncion");
      
        trFuncion.innerHTML = '';
        bodyFuncion.innerHTML = '';
      
        header.forEach(h => {
          let cell = document.createElement("th");
          cell.innerHTML = h;
          trFuncion.appendChild(cell);
        });
      
        rows.forEach(rowData => {
          const range = thresholds[rowData.codigo_parametro];
          let row = document.createElement("tr");
      
          if (range) {
            const valor = parseFloat(rowData.valor);
            if (range[0] === -Infinity && valor > range[1]) {
              row.classList.add("highlight-row");
              console.log("Fuera de rango: ", rowData);
            } else if (range[1] === Infinity && valor < range[0]) {
              row.classList.add("highlight-row");
              console.log("Fuera de rango: ", rowData);
            } else if (valor < range[0] || valor > range[1]) {
              row.classList.add("highlight-row");
              console.log("Fuera de rango: ", rowData);
            }
          }
      
          Object.values(rowData).forEach(value => {
            let cell = document.createElement("td");
            cell.innerHTML = value;
            row.appendChild(cell);
          });
      
          bodyFuncion.appendChild(row);
        });
      
        $('#dataTable').DataTable({
          destroy: true,
          lengthMenu: [[5, 10, 20, -1], [5, 10, 20, 'Todos']],
        });
      };
      
      // No hay llamado a la función createDataTable aquí
      
    
    
      const createGraphPr = (Datos) => {

        console.log(Datos);
        let datetime = [];
    
        for (let data of Datos.data) {
            datetime.push(data.datetime);
        }
    
        console.log(datetime);
    
    
        // Obtiene el threshold para el nombre de Datos, o un valor predeterminado si no se encuentra
        let threshold = thresholds[Datos.name[0]] || [-Infinity, Infinity];
    
        let subtitleText = "";
        let showSubtitle = false;
    
        // Verificar si la clave existe en el objeto thresholds
        if(thresholds.hasOwnProperty(Datos.name[0])) {
            showSubtitle = true;
            if(threshold[0] === -Infinity) {
                subtitleText = '<= ' + threshold[1];
            } else if(threshold[1] === Infinity) {
                subtitleText = '>= ' + threshold[0];
            } else {
                subtitleText = threshold[0] + ' - ' + threshold[1];
            }
        }
    
        Highcharts.chart('container7', {
            chart: {
                type: 'line',
                zoomType: 'x'
            },
            title: {
                text: 'Muestra'
            },
            subtitle: {
                align: 'right',
                verticalAlign: 'top',
                x: -30,
                y: 30,
                useHTML: true,
                text: showSubtitle
                    ? '<span style="color: #7cb5ec; padding: 0 10px; border-left: solid 20px #7cb5ec;">&#8203;</span> Normal (' + subtitleText + ')' +
                      '<br><span style="color: #ff0000; padding: 0 10px; border-left: solid 20px #ff0000;">&#8203;</span> Fuera de rango'
                    : '',
            },
            tooltip: {
                formatter: function() {
                    return Datos.name + ': ' + this.y;
                },            
                shared: true,
                split: false,
            },
            xAxis: {
                categories: datetime,
            },
            yAxis: {
                title: {
                    text: 'valor'
                }
            },
            legend: {
                enabled: false
            },
            plotOptions: {
                area: {
                    fillColor: {
                        linearGradient: {
                            x1: 0,
                            y1: 0,
                            x2: 0,
                            y2: 1
                        },
                        stops: [
                            [0, Highcharts.getOptions().colors[0]],
                            [1, Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
                        ]
                    },
                    marker: {
                        radius: 2
                    },
                    lineWidth: 1,
                    states: {
                        hover: {
                            lineWidth: 1
                        }
                    },
                    threshold: null
                }
            },
            series: [{
                data: Datos.data.map(d => d.y),
                zones: [
                    {
                        value: threshold[0], // por debajo del umbral inferior
                        color: '#ff0000'
                    },
                    {
                        value: threshold[1], // dentro de los umbrales
                        color: '#7cb5ec'
                    },
                    {
                        value: Infinity, // por encima del umbral superior
                        color: '#ff0000'
                    }
                ]
            }]
        });
    };
    

export const dataTable = async ({ UTM_ESTE, UTM_NORTE, fechaInicio, fechaTermino, filtro, numeroLocalidad }) => {

    if (filtro === 'PR14') {
        await axios.post(`${process.env.REACT_APP_IP_ADDRESS}/dataTable`, { UTM_ESTE: UTM_ESTE, UTM_NORTE: UTM_NORTE, fechaInicio: fechaInicio, fechaTermino: fechaTermino })
            .then((response) => {
                console.log(response)
                console.log(response.data.dataTable);



                const areasSelect = document.getElementById('PR14');

                for (let i = areasSelect.options.length; i >= 0; i--) {

                    areasSelect.remove(i);
                }

                for (let dato in response.data.array_parametros) {

                    let opcion = document.createElement('option')
                    opcion.value = dato
                    opcion.text = response.data.array_parametros[dato]
                    areasSelect.add(opcion)
                }
                const element = document.getElementById("selectPr14");

                element.classList.remove('no-display');




                createDataTable({ header: response.data.header_table, rows: response.data.dataTable })

            });
    } else {
        await axios.post(`${process.env.REACT_APP_IP_ADDRESS}/dataTable23`, { cod_planta: numeroLocalidad, fechaInicio: fechaInicio, fechaTermino: fechaTermino, categoria: filtro })
            .then((response) => {

                console.log(response.data.dataTable)
                console.log("==========================*")

                const areasSelect = document.getElementById('PR23');


                for (let i = areasSelect.options.length; i >= 0; i--) {

                    areasSelect.remove(i);
                }

                for (let dato in response.data.array_parametros) {

                    let opcion = document.createElement('option')
                    opcion.value = dato
                    opcion.text = response.data.array_parametros[dato]
                    areasSelect.add(opcion)
                }
                const element = document.getElementById("selectPr23");

                element.classList.remove('no-display');



                console.log(response.data)
                createDataTable({ header: response.data.header_table, rows: response.data.dataTable })


            })




    }







}


export const functionMapGraph = (cordenadas, location) => {
    map.setView(cordenadas, 16);
    map.removeLayer(theMarker);
    theMarker = new window.L.Marker(cordenadas).addTo(map);

    theMarker.bindPopup('<p>' + location + '</p>').openPopup();
    theMarker.bindPopup('<p>' + location + '</p>').openPopup();
    console.log('funcion click map')
}


//Fin Funciones principales para PR14 Y PR23



export const graphData = async ({ parametro, filtro }) => {
    let fecha_inicio = $('#picker').data('daterangepicker').startDate.format("YYYY-MM-DD");
    let fecha_termino = $('#picker').data('daterangepicker').endDate.format("YYYY-MM-DD");

    if (filtro === "PR14") {
        console.log("entre aca")
        await axios.post(`${process.env.REACT_APP_IP_ADDRESS}/graphData`,
            {
                timeStart: timeStart,
                timeEnd: timeEnd,
                uNorte: uNorte,
                uEste: uEste,
                parametro: parametro
            }).then((response => { createGraphPr(response.data) }))
    } else {


        console.log(categoria)

        await axios.post(`${process.env.REACT_APP_IP_ADDRESS}/graphData23`,
            {
                timeStart: fecha_inicio,
                timeEnd: fecha_termino,
                parametro: parametro,
                cod_planta: cod_planta,
                categoria: categoria

            }).then((response => { createGraphPr(response.data) }))
    }













}


export const moverMapa = async ({ cordenadas = [-29.9212, -71.2481], tipo, numeroLocalidad, localidad, typeEvent, filtro }) => {
    const fechaInicio = $('#picker').data('daterangepicker').startDate.format("YYYY-MM-DD");
    const fechaTermino = $('#picker').data('daterangepicker').endDate.format("YYYY-MM-DD");



    if (numeroLocalidad !== undefined) { numero_loc = numeroLocalidad }

    console.log(filtro)
    if (typeof numeroLocalidad === 'string') { numero_loc = parseInt(numeroLocalidad.split('-')[0]) } else { console.log('esto es un number') }



    var numeroLocalidadA;
    var tipoDato = '';
    if (tipo !== '') { tipoDato = tipo };

    if (filtro === 'Presiones') {
        map.removeLayer(layoutNew)
        map.removeLayer(pts_control_shape)
        map.removeLayer(sector_shape)
        map.removeLayer(pts_control_shape_pr14)
        map.removeLayer(pts_control_shape_pr23)
        map.removeLayer(sector_calidad_shape)

        const newSectorJson = sectorJson.features.filter(element => element.properties.cod_loc === numeroLocalidad)
        const newPcpJson = pcpJson.features.filter(element => element.properties.cod_loc === numeroLocalidad)


        pts_control_shape = window.L.geoJSON(newPcpJson, {
            pointToLayer: function (feature, latlng) {
                return window.L.circleMarker(latlng, {
                    fill: true,
                    radius: 5,
                });
            },
            onEachFeature: addMyData_pts_control_shape,

        });




        sector_shape = window.L.geoJSON(newSectorJson, {
            onEachFeature: addMyData_sectores_shape,
            style: {
                "color": "#a71400",
            }
        })



        map.addLayer(sector_shape)
        map.addLayer(pts_control_shape)

        function addMyData_sectores_shape(feature, layer) {
            sector_shape.addLayer(layer);
            layer.bindPopup(Object.keys(feature.properties).map(function (k) {
                return k + ": " + feature.properties[k];
            }).join("<br />"), {
                maxHeight: 200
            });


            //  layer
            // .on("click", draw_chart_sector)
            //  .on("mouseover", highlightFeatureSectoresShape)
            //  .on("mouseout", resetHighlightSectoresShape)
            // .on("dblclick", zoom_sector);
        }


        function addMyData_pts_control_shape(feature, layer) {
            pts_control_shape.addLayer(layer);
            layer.bindPopup(Object.keys(feature.properties).map(function (k) {
                return k + ": " + feature.properties[k];
            }).join("<br />"), {
                maxHeight: 200
            });

            layer
                .on("click", draw_chart_pto_presion)
                .on("mouseover", highlightFeatureCuartelesShape)
                .on("mouseout", resetHighlightPresionesShape)
        }

        console.log('aca deberia ejecutarse la funcion de presiones')
    } else if (filtro == 'Turbiedad'){
        console.log("ENTRE A TURBIEDAD")
        map.removeLayer(layoutNew)
        map.removeLayer(pts_control_shape)
        map.removeLayer(sector_shape)
        map.removeLayer(pts_control_shape_pr14)
        map.removeLayer(pts_control_shape_pr23)
        map.removeLayer(sector_calidad_shape)

        const newSectorCalidadJson = sectorCalidadJson.features.filter(element => element.properties.cod_loc === numeroLocalidad)




        sector_calidad_shape = window.L.geoJSON(newSectorCalidadJson, {
            onEachFeature: addMyData_sectores_shape,
            style: {
                "color": "#a71400",
            }
        })



        map.addLayer(sector_calidad_shape)

        function addMyData_sectores_shape(feature, layer) {
            sector_calidad_shape.addLayer(layer);
            layer.bindPopup(Object.keys(feature.properties).map(function (k) {
                return k + ": " + feature.properties[k];
            }).join("<br />"), {
                maxHeight: 200
            });


             layer
            .on("click", draw_chart_sector_calidad)
            //  .on("mouseover", highlightFeatureSectoresShape)
            //  .on("mouseout", resetHighlightSectoresShape)
            // .on("dblclick", zoom_sector);
        }



        console.log('aca deberia ejecutarse la funcion de turbiedad')
    } else if (filtro === 'PR14') {




        const dataJson = await axios.post(`${process.env.REACT_APP_IP_ADDRESS}/geoJson`, {

            numero_localidad: numeroLocalidad,
            fecha_inicio: fechaInicio,
            fecha_termino: fechaTermino

        })



        console.log(dataJson)


        map.removeLayer(layoutNew)
        map.removeLayer(pts_control_shape)
        map.removeLayer(sector_shape)
        map.removeLayer(pts_control_shape_pr14)
        map.removeLayer(pts_control_shape_pr23)
        map.removeLayer(sector_calidad_shape)


        // const newPr14 = dataJson.data.features.filter(element => element.properties.cod_loc === numeroLocalidad)



        pts_control_shape_pr14 = window.L.geoJSON(dataJson.data.features, {
            pointToLayer: function (feature, latlng) {
                return window.L.circleMarker(latlng, {
                    fill: true,
                    radius: 5,
                });
            },
            onEachFeature: addMyData_pts_control_shape,

        });




        map.addLayer(pts_control_shape_pr14)

        function addMyData_pts_control_shape(feature, layer) {
            pts_control_shape_pr14.addLayer(layer);
            layer.bindPopup(Object.keys(feature.properties).map(function (k) {
                return k + ": " + feature.properties[k];
            }).join("<br />"), {
                maxHeight: 200
            });

            layer
                .on("click", draw_chart_pr14)
            //  .on("mouseover", highlightFeatureCliente)
            //  .on("mouseout", resetHighlightCliente);
        }


        console.log('filtro de pr14')
    } else if (filtro === 'Variables Operacionales' || filtro === 'Muestras Autocontrol' || filtro === 'Muestras Lodos') {

        categoria = filtro;
        const dataJson = await axios.post(`${process.env.REACT_APP_IP_ADDRESS}/geoJson23`, { numero_localidad: numeroLocalidad })
        console.log(dataJson.data.features)

        map.removeLayer(layoutNew)
        map.removeLayer(pts_control_shape)
        map.removeLayer(sector_shape)
        map.removeLayer(pts_control_shape_pr14)
        map.removeLayer(pts_control_shape_pr23)


        pts_control_shape_pr23 = window.L.geoJSON(dataJson.data.features, {
            pointToLayer: function (feature, latlng) {
                return window.L.circleMarker(latlng, {
                    fill: true,
                    radius: 15,
                });
            },
            onEachFeature: addMyData_pts_control_shape,

        });




        map.addLayer(pts_control_shape_pr23)

        function addMyData_pts_control_shape(feature, layer) {
            pts_control_shape_pr23.addLayer(layer);
            layer.bindPopup(Object.keys(feature.properties).map(function (k) {
                return k + ": " + feature.properties[k];
            }).join("<br />"), {
                maxHeight: 200
            });

            // layer
            //     .on("click", draw_chart_pr14)
            // //  .on("mouseover", highlightFeatureCliente)
            // //  .on("mouseout", resetHighlightCliente);
        }

        const codigoPlanta = dataJson.data.features[0].properties.Codigo_Planta;
        console.log(codigoPlanta);
        cod_planta = codigoPlanta;

        if (contador !== undefined) {
            var table = $('#dataTable').DataTable();
            table.destroy();

        } else {
            contador = contador + 1;
        }

        dataTable({ fechaInicio: fechaInicio, fechaTermino: fechaTermino, filtro: filtro, numeroLocalidad: codigoPlanta });


    }
    else {




        map.removeLayer(sector_shape)
        map.removeLayer(pts_control_shape)
        map.removeLayer(pts_control_shape_pr23)
        map.removeLayer(pts_control_shape_pr14)
        map.removeLayer(sector_calidad_shape)


        if (theMarker !== undefined) { map.removeLayer(theMarker) };
        theMarker = new window.L.Marker(cordenadas).addTo(map);
        theMarker.bindPopup('<p>' + localidad + ' (' + tipoDato + ')</p>').openPopup();

        const cuarteles_shape = window.L.layerGroup().addTo(map);
        const datoNuevo = [];
        if (tipoDato === 'Cuartel') {
            map.removeLayer(reclamo_layer)
            map.removeLayer(cliente_layer)
            map.removeLayer(red_layer)
            map.removeLayer(sector_calidad_shape)
            map.removeLayer(corte_layer)

            if (typeEvent === 'noclick') { numeroLocalidadA = datoJson.features.filter(element => element.properties.cod_cuart === numeroLocalidad)[0].properties.cod_loc }

        } else if (tipoDato === 'Localidad') {
            map.removeLayer(reclamo_layer)
            map.removeLayer(cliente_layer)
            map.removeLayer(red_layer)
            map.removeLayer(sector_calidad_shape)
            map.removeLayer(corte_layer)

            numeroLocalidadA = numeroLocalidad;
        }

        for (let property in datoJson.features) {

            if (datoJson.features[property].properties.cod_loc === numeroLocalidadA) {
                datoNuevo.push(datoJson.features[property])
            }
        }
        if (tipoDato === 'Localidad' || tipoDato === 'Cuartel' && typeEvent === 'noclick') {
            map.removeLayer(layoutNew)
            layoutNew = window.L.geoJSON(datoNuevo, {
                onEachFeature: addMyData_cuarteles_shape,
                style: cuarteles_style_select,
            });

            map.addLayer(layoutNew)

        }
        if (tipoDato === 'Localidad') {
            map.setView(cordenadas, 13.5);
        } else if (tipoDato === 'Cliente') {
            map.setView(cordenadas, 18);
        } else if (tipoDato === 'Cuartel') {
            map.setView(cordenadas, 14.3);

        }
        function addMyData_cuarteles_shape(feature, layer) {
            cuarteles_shape.addLayer(layer);
            layer
                .on("click", draw_chart_cuartel)
                .on("mouseover", highlightFeatureCuartelesShape)
                .on("mouseout", resetHighlightCuartelesShape)
                .on("dblclick", function (e) {

                    var currentLayer = e.target;
                    var numero_cuartel = currentLayer.feature.properties.cod_cuart;
                    var fecha_inicio = $('#picker').data('daterangepicker').startDate.format("YYYY-MM-DD");
                    var fecha_termino = $('#picker').data('daterangepicker').endDate.format("YYYY-MM-DD");
                    map.removeLayer(cuarteles_shape);


                    axios.post(`${process.env.REACT_APP_IP_ADDRESS}/todo_en_cuartel`,
                        { 'fecha_inicio': fecha_inicio, 'fecha_termino': fecha_termino, 'numero_cuartel': numero_cuartel })
                        .then((response) => {

                            console.log(response);
                            console.log(typeof response);

                            map.removeLayer(red_layer)
                            red_layer = window.L.geoJSON(response.data[0], {
                                onEachFeature: function onEachFeatureRedes(feature, layer) {

                                    layer.bindPopup(Object.keys(feature.properties).map(function (k) {
                                        return k + ": " + feature.properties[k];
                                    }).join("<br />"), {
                                        maxHeight: 200
                                    });
                                    //  layer
                                    //  .on("mouseover", highlightFeatureRedesShape)
                                    //  .on("mouseout", resetHighlightRedesShape);
                                },
                                weight: 5,
                            });
                            map.addLayer(red_layer);

                            map.removeLayer(cliente_layer)
                            cliente_layer = window.L.geoJSON(response.data[1], {
                                onEachFeature: function onEachFeatureCliente(feature, layer) {

                                    layer.bindPopup(Object.keys(feature.properties).map(function (k) {
                                        return k + ": " + feature.properties[k];
                                    }).join("<br />"), {
                                        maxHeight: 200
                                    });
                                    layer
                                        .on("click", draw_chart_cliente)
                                    //  .on("mouseover", highlightFeatureCliente)
                                    //  .on("mouseout", resetHighlightCliente);
                                },

                                pointToLayer: function (feature, latlng) {
                                    return window.L.circleMarker(latlng, {
                                        stroke: false,
                                        fill: true,
                                        color: "#000000",
                                        fillColor: "#000000",
                                    });
                                },


                            });
                            map.addLayer(cliente_layer);



                            map.removeLayer(corte_layer)
                            corte_layer = window.L.geoJSON(response.data[2], {
                                onEachFeature: function onEachFeatureCorte(feature, layer) {

                                    layer.bindPopup(Object.keys(feature.properties).map(function (k) {
                                        return k + ": " + feature.properties[k];
                                    }).join("<br />"), {
                                        maxHeight: 200
                                    });
                                    //  layer
                                    //  .on("mouseover", highlightFeatureCorte)
                                    //  .on("mouseout", resetHighlightCorte);
                                },

                                pointToLayer: function (feature, latlng) {
                                    return window.L.circleMarker(latlng, {
                                        stroke: false,
                                        fill: true,
                                        fillColor: "#0275d8",
                                        fillOpacity: 0.7,
                                    });
                                },

                            });
                            map.addLayer(corte_layer);


                            map.removeLayer(reclamo_layer)
                            reclamo_layer = window.L.geoJSON(response.data[3], {
                                onEachFeature: function onEachFeatureReclamo(feature, layer) {

                                    layer.bindPopup(Object.keys(feature.properties).map(function (k) {
                                        return k + ": " + feature.properties[k];
                                    }).join("<br />"), {
                                        maxHeight: 200
                                    });
                                    //  layer
                                    //  .on("mouseover", highlightFeatureReclamo)
                                    //  .on("mouseout", resetHighlightReclamo);
                                },

                                pointToLayer: function (feature, latlng) {
                                    return window.L.circleMarker(latlng, {
                                        stroke: false,
                                        fill: true,
                                        fillColor: "#d9534f",
                                        fillOpacity: 0.7,

                                    });
                                },

                            });
                            map.addLayer(reclamo_layer);


                        })
                        .catch(function (error) {
                            console.log(error);
                        });




                });

        }
    }




}

export const mapaFuncion = () => {

    const cuarteles_shape = window.L.layerGroup().addTo(map);
    map.doubleClickZoom.disable();




    window.L.geoJSON(datoJson, {
        onEachFeature: addMyData_cuarteles_shape,
        style: cuarteles_style,

    })





    const pts_control_shape = window.L.layerGroup().addTo(map)

    // Se crea el layer comunas shape, que está vacío por ahora



    window.L.geoJSON(pcpJson, {


        pointToLayer: function (feature, latlng) {
            return window.L.circleMarker(latlng, {
                fill: true,
                radius: 5,
            });
        },
        onEachFeature: addMyData_pts_control_shape,

    })


    // Funcion que adiciona acciones con mouseover y mouse click
    function addMyData_pts_control_shape(feature, layer) {


        pts_control_shape.addLayer(layer);
        map.removeLayer(pts_control_shape);
        layer.bindPopup(Object.keys(feature.properties).map(function (k) {
            return k + ": " + feature.properties[k];
        }).join("<br />"), {
            maxHeight: 200
        });

        layer
            //   .on("click", draw_chart_pto_presion)
            .on("mouseover", highlightFeatureCuartelesShape)
            .on("mouseout", resetHighlightCuartelesShape)
    }



    function addMyData_cuarteles_shape(feature, layer) {




        cuarteles_shape.addLayer(layer);
        map.removeLayer(cuarteles_shape);


        layer
            .on("click", draw_chart_cuartel)
            .on("mouseover", highlightFeatureCuartelesShape)
            .on("mouseout", resetHighlightCuartelesShape)
            .on("dblclick", function (e) {

                var currentLayer = e.target;
                var numero_cuartel = currentLayer.feature.properties.cod_cuart;
                var fecha_inicio = $('#picker').data('daterangepicker').startDate.format("YYYY-MM-DD");
                var fecha_termino = $('#picker').data('daterangepicker').endDate.format("YYYY-MM-DD");
                map.removeLayer(cuarteles_shape);


                axios.post(`${process.env.REACT_APP_IP_ADDRESS}/todo_en_cuartel`,
                    { 'fecha_inicio': fecha_inicio, 'fecha_termino': fecha_termino, 'numero_cuartel': numero_cuartel })
                    .then((response) => {



                        map.removeLayer(red_layer)
                        red_layer = window.L.geoJSON(response.data[0], {
                            onEachFeature: function onEachFeatureRedes(feature, layer) {

                                layer.bindPopup(Object.keys(feature.properties).map(function (k) {
                                    return k + ": " + feature.properties[k];
                                }).join("<br />"), {
                                    maxHeight: 200
                                });
                                //esta no
                                //  layer
                                //  .on("mouseover", highlightFeatureRedesShape)
                                //  .on("mouseout", resetHighlightRedesShape);
                            },
                            weight: 5,
                        });
                        map.addLayer(red_layer);

                        map.removeLayer(cliente_layer)
                        cliente_layer = window.L.geoJSON(response.data[1], {
                            onEachFeature: function onEachFeatureCliente(feature, layer) {

                                layer.bindPopup(Object.keys(feature.properties).map(function (k) {
                                    return k + ": " + feature.properties[k];
                                }).join("<br />"), {
                                    maxHeight: 200
                                });
                                layer
                                    .on("click", draw_chart_cliente)
                                //  .on("mouseover", highlightFeatureCuartelesShape)
                                //  .on("mouseout", resetHighlightCliente);
                            },

                            pointToLayer: function (feature, latlng) {
                                return window.L.circleMarker(latlng, {
                                    stroke: false,
                                    fill: true,
                                    color: "#000000",
                                    fillColor: "#000000",
                                });
                            },


                        });
                        map.addLayer(cliente_layer);



                        map.removeLayer(corte_layer)
                        corte_layer = window.L.geoJSON(response.data[2], {
                            onEachFeature: function onEachFeatureCorte(feature, layer) {

                                layer.bindPopup(Object.keys(feature.properties).map(function (k) {
                                    return k + ": " + feature.properties[k];
                                }).join("<br />"), {
                                    maxHeight: 200
                                });
                                //  layer
                                //  .on("mouseover", highlightFeatureCorte)
                                //  .on("mouseout", resetHighlightCorte);
                            },

                            pointToLayer: function (feature, latlng) {
                                return window.L.circleMarker(latlng, {
                                    stroke: false,
                                    fill: true,
                                    fillColor: "#0275d8",
                                    fillOpacity: 0.7,
                                });
                            },

                        });
                        map.addLayer(corte_layer);


                        map.removeLayer(reclamo_layer)
                        reclamo_layer = window.L.geoJSON(response.data[3], {
                            onEachFeature: function onEachFeatureReclamo(feature, layer) {

                                layer.bindPopup(Object.keys(feature.properties).map(function (k) {
                                    return k + ": " + feature.properties[k];
                                }).join("<br />"), {
                                    maxHeight: 200
                                });
                                //  layer
                                //  .on("mouseover", highlightFeatureReclamo)
                                //  .on("mouseout", resetHighlightReclamo);
                            },

                            pointToLayer: function (feature, latlng) {
                                return window.L.circleMarker(latlng, {
                                    stroke: false,
                                    fill: true,
                                    fillColor: "#d9534f",
                                    fillOpacity: 0.7,

                                });
                            },

                        });
                        map.addLayer(reclamo_layer);


                    })
                    .catch(function (error) {
                        console.log(error);
                    });




            });

    }

    // Cotrol de layers

    const basemapControl = {
        "Normal": basemap,

        //se pueden agregar mas mapas aqui

    }

    const layerControl2 = {
        "pto_control": pts_control_shape,
        "Cuarteles": cuarteles_shape,
    }

    // se adiciona el componente de control, con checkboxes para geolayers y radio botton para mapas
    new window.L.control.layers(basemapControl, layerControl2).addTo(map);





}









