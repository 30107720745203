import axios from 'axios'
import React, { useEffect} from 'react'
import {} from 'select2'
import {} from 'daterangepicker'
import $ from 'jquery'
import ButtonOptions from './ButtonOptions'
import moment from 'moment'
import { search_selected } from '../Helpers/utils';
import { filtro_global } from '../Pages/Main/main.jsx';

const Header = (props) => {

    var fechaInicio = '';
    var fechaFinal = '';
    var tipoLocalidad = '';


    const formatResult = (result) => {


        var html = '<div>' +
            '<h4>' + result.items.text + '</h4></div>';
        //return html;
        return $(html);

    }


    const generarFecha = async () => {

        const datos = await axios.get(`${process.env.REACT_APP_IP_ADDRESS}/lastDateTime`);



        var start = new Date(datos.data.startDate);
        var end = new Date(datos.data.endDate);
        var contador = 0;
    

        const cb = (start, end, label) => {

           

            if (contador >= 1) {
                contador = contador + 1;
                $('#picker span').html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
                fechaInicio = start.format('YYYY-MM-DD');
                fechaFinal = end.format('YYYY-MM-DD');
                const placeholder = document.querySelector('.select2-selection__placeholder');
              
           
                if (!placeholder) { 

                    let location = document.querySelector('#select2-select2-container').textContent;
        
                    console.log(location)
                    console.log(fechaFinal)
                    console.log(fechaInicio)
                    tipoLocalidad = location.split('(')[1].split(')')[0];
                   
                    search_selected(location, fechaInicio, fechaFinal, tipoLocalidad, filtro_global) 
                }
                console.log(tipoLocalidad)
                console.log("A new date selection was made: " + start.format('YYYY-MM-DD') + ' to ' + end.format('YYYY-MM-DD'));
            } else {
                contador = contador + 1;
                let location = $('#select2').val();
               
                console.log(typeof start);
                $('#picker span').html(start.toDateString() + ' - ' + end.toDateString());
                console.log(fechaInicio)
                console.log(fechaFinal)
                fechaInicio = start.toISOString().slice(0, 10);
                fechaFinal = end.toISOString().slice(0, 10);

                const placeholder = document.querySelector('.select2-selection__placeholder');
                if (!placeholder) { 

                    tipoLocalidad = location.split('(')[1].split(')')[0];
                    search_selected(location, fechaInicio, fechaFinal, tipoLocalidad, filtro_global) 
                
                }
                console.log("A new date selection was made: " + start.toDateString() + ' - ' + end.toDateString());


            }

        }


        $('#picker').daterangepicker({

            startDate: start,
            endDate: end,
            ranges: {
                'Últimos 30 Días': [moment().locale("es").subtract(1, 'month'), moment().locale("es")],
                'Últimos 60 Días': [moment().locale("es").subtract(2, 'month'), moment().locale("es")],
                'Últimos 90 Días': [moment().locale("es").subtract(3, 'month'), moment().locale("es")],
                'Año Pasado': [moment().locale("es").subtract(1, 'year').startOf('year'), moment().locale("es").subtract(1, 'year').endOf('year')],
                'Año Antepasado': [moment().locale("es").subtract(2, 'year').startOf('year'), moment().locale("es").subtract(2, 'year').endOf('year')],
                'Todo': [moment().locale("es").subtract(3, 'year').startOf('year'), moment().locale("es")],
            }
        }, cb);



        cb(start, end);


    };

  



    useEffect(() => {

        setTimeout(() => {
            generarFecha();
        }, 1000)


        $('.js-data-example-ajax').select2({
            placeholder: '<p style="margin-top:5px; float: left;"> <i style="color:#4da6ef;" class="fa-solid fa-location-dot"></i> Seleccionar Lugar</p>',
            minimumInputLength: 1,
            escapeMarkup: function (markup) { return markup; },
            ajax: {

                url: `${process.env.REACT_APP_IP_ADDRESS}/search_values`,
                type: "POST",
                delay: 250,
                data: function (params) {

                    return {
                        locationVal: params.term
                    };
                },
                processResults: function (data) {
                    return {
                        results: $.map(data.items, function (val) {
                            return { id: val.text, text: val.text }
                        })
                    };
                },
                templateResult: formatResult,

            },

        });


        $('#select2').on('select2:select', function (e) {
         
            document.getElementById("container1").classList.add('no-display');
            document.getElementById("container2").classList.add('no-display');
            document.getElementById("container3").classList.add('no-display');
            document.getElementById("container4").classList.add('no-display');
            document.getElementById("container5").classList.add('no-display');
            document.getElementById('title').innerHTML = 'Resumen';
            document.getElementById('subtitle').innerHTML = `Menú &gt; Resumen`;
            document.getElementById('plot-header-ind').innerHTML = 'Resumen';
           
            let location = $('#select2').val();
            let tipo = location.split('(')[1].split(')')[0];
         
            document.querySelector('#map-header-text').textContent = `${location}`;
            if(tipo === 'Cliente'){search_selected(location,fechaInicio,fechaFinal,tipo,'Seach');
            console.log('cliente por buscador')
            $("#select2").val(null);
            }else{
                search_selected(location, fechaInicio, fechaFinal, tipo, 'Resumen');
                console.log('busqueda normal')
                $("#select2").val(null);
            }
        });
    },[])



    return (
        <React.Fragment>

            <div className="row header-styles">
                <div className="col-3">
                    <div className="indice">
                        <h2 id="title">Facturación</h2>
                        <h4 id="subtitle">Menú &gt; Facturación</h4>
                    </div>
                </div>
                <div className="col-3">
                    <div className="loc">

                    <select id='select2' className="js-data-example-ajax"></select>

                </div>


                </div>
                <div className="col-3">
                    <div className="date">
                        <div id="picker">
                            <i id='date-calendar-icon' className="fas fa-calendar-alt"></i>&nbsp;
                            <span></span> <i className="fa fa-caret-down"></i>
                        </div>
                    </div>
                </div>
                <div className="col-3"> <ButtonOptions /> </div>
            </div>


        </React.Fragment>
    )
}

export default Header