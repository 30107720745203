import React, { useEffect, useState } from 'react'
import '../Pages/Main/main.css'
import Pr from '../Pages/Pr/Pr'
import LogoSsat from '../Assets/Images/ssat_logo.png'
import LogoAguaPatagonia from '../Assets/Images/aguas_patagonias.png'

const MenuLateral = (props) => {

  const filterOptions = (a) => {
    const select = document.querySelector('#select2-select2-container');
    const placeholder = document.querySelector('.select2-selection__placeholder');

    (!placeholder) ? props.filterBySearch(a, select.textContent) : alert('se debe selecionar primero algo para poder filtrar')

  }
  return (
    <React.Fragment>




      <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Download Pr</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <Pr />
            </div>

          </div>
        </div>
      </div>

      <nav id="vnav-menu">
        <ul className="vnav-menu-items">



          <div id="vnavbar-toggle">
            <a href="#" id="ssat-logo">
              <img src={LogoSsat} alt="ssat" width="35" height="43" />
            </a>
            <a href="#" id="ssat-text">SSAT</a>
          </div>


          <div className="vnav-section">

            <div className="vnav-text" id="btn-total" onClick={() => filterOptions('Resumen')}><a href="#">Resumen</a></div>
            <div className="vnav-text" id="btn-cortes" onClick={() => filterOptions('Cortes')}> <a href="#">Cortes</a></div>
            <div className="vnav-text" id="btn-reclamos" onClick={() => filterOptions('Reclamos')}><a href="#">Reclamos</a></div>
            <div className="vnav-text" id="btn-ofertaDemanda" onClick={() => filterOptions('Facturación')} ><a href="#">Facturación</a></div>
            <div className="vnav-text">  <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal">Download Pr</a> </div>
            <div className="vnav-text" id="btn-presiones" > <a onClick={() => filterOptions('Presiones')} href="#">Presiones</a></div>
            <div className="vnav-text" id="btn-pr" > <a onClick={() => filterOptions('PR14')} href="#">PR14</a></div>
            <div className="btn-group dropend"> <div className="vnav-text2" data-bs-toggle="dropdown" aria-expanded="false"><a href="#">PR23</a></div>
              <ul className="dropdown-menu" style={{ width: '110%' }}>
                <li className="lii_pr23"><a className="li_pr23" onClick={() => filterOptions('Variables Operacionales')} href="#">Variables Operacionales</a></li>
                <li className="lii_pr23"><a className="li_pr23" onClick={() => filterOptions('Muestras Autocontrol')} href="#">Muestras Autocontrol</a></li>
                <li><a className="li_pr23" onClick={() => filterOptions('Muestras Lodos')} href="#">Muestras Lodos</a></li>
              </ul>

            </div>
            <div className="vnav-text" id="btn-turbiedad" onClick={() => filterOptions('Turbiedad')}><a href="#">Turbiedad</a></div>

          </div>


          <img src={LogoAguaPatagonia} className="logoPatagonia" alt="ssat" />
        </ul>

      </nav>

    </React.Fragment>
  )
}

export default MenuLateral