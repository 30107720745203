
import React, { useState } from 'react'
import DatePicker from 'react-datepicker'
import $ from 'jquery'
import './pr.css'
import '../../Helpers/selectPr'
import axios from 'axios'




const Pr = () => {

    const [startDate, setStartDate] = useState(new Date("2022/02/08"));
    const [endDate, setEndDate] = useState(new Date("2022/04/08"));
 
    var archivosSeleccionados = []



    $(document).ready(function() {
      
        $("#comunas").select2({
            tags: true,
            placeholder: "Seleccione archivos",
            tokenSeparators: [',', ' ']
        })
     
        
        $('#comunas').on('select2:select', function (e) {
            archivosSeleccionados.push(e.params.data.text);
            console.log(archivosSeleccionados);
            console.log(startDate)
            console.log(endDate)

        });

        $('#comunas').on('select2:unselect', function (e) {

            let item = e.params.data.text;

            archivosSeleccionados = archivosSeleccionados.filter(f => f !== item)
            console.log(archivosSeleccionados);

        });
    });

 



    const eventDownload = () => {
        document.getElementById('containerSpinner').classList.remove('noDisplay');
        document.getElementById('containerDownload').classList.add('noDisplay');
        const url = `${process.env.REACT_APP_IP_ADDRESS}/downloadPr`;
        const config = { responseType: 'blob' };
        const data = {'dateStart': startDate, 'dateEnd': endDate,
        'fileArray': archivosSeleccionados};

        axios({
            method:'post',
            url: url,
            data: data, 
            responseType: "arraybuffer"
        }
        ).then((response) => {
             console.log(response)
            //  const url = new Blob([response.data],{type:'application/zip'});
             const url = window.URL.createObjectURL(new Blob([response.data]));
             const link = document.createElement('a');
             link.href = url;
             link.setAttribute('download','download.zip'); 
             document.body.appendChild(link);
             link.click();
             document.getElementById('comunas').innerHTML = ""
            document.getElementById('selected_value').setAttribute('selected', true);
            document.getElementById('containerSpinner').classList.add('noDisplay');
            document.getElementById('containerDownload').classList.remove('noDisplay');
            
            })        


        // (archivosSeleccionados.length === 0) ? alert('seleccione archivos para continuar') : axios.post('http://localhost:5000/downloadPr',
        // {
        //     'dateStart': startDate, 'dateEnd': endDate,
        //     'fileArray': archivosSeleccionados
        // }).then((response) => { console.log(response)})

    }


    return (
        <React.Fragment>



<div id="containerSpinner" className="noDisplay ">
<div className="spinner-border text-info" role="status">
  <span className="visually-hidden">Loading...</span>
</div>

<h4 className="cargandoH4">Cargando...</h4>
</div>
            <div id="containerDownload" className="row">

                <div className="col-6 inputDownload">
                    <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                    /></div>

                <div className="col-6 inputDownload" > <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    dateFormat="MM/yyyy"
                    showMonthYearPicker
                /></div>

                <div className="col-12">
                    <select className="form-select" onChange={(e) => {archivosSeleccionados = [];
                        document.getElementById('selected_value').removeAttribute('selected');} } id="regiones"></select>



                </div>
                <div id='selectMulti' className="col-12 "> <select className="form-select " name="states[]" multiple="multiple" id='comunas'>

                </select></div>
                <div className="col-md-12 text-center"><button type="button" className="btn btn-primary btn-download" onClick={eventDownload}>Descargar</button></div>
            


            </div>
        
        </React.Fragment>
    )
}

export default Pr