import React, { useState } from 'react'
import MenuLateral from '../../Components/MenuLateral'
import Header from '../../Components/Header'
import Map from '../../Components/ContainerMap'
import { search_selected } from '../../Helpers/utils';
import { graphData,numero_sector_calidad_str } from '../../Helpers/funcionMap';
import $ from 'jquery';
import { } from 'datatables';

export var filtro_global = "Resumen";

const Main = () => {

    const [busqueda, setBusqueda] = useState()


    const changeMap = (a) => {
        setBusqueda(a)

    }




    $(document).ready(function () {

        $("#PR14").select2({
            tags: true,
            placeholder: "Seleccione archivos",
            tokenSeparators: [',', ' ']
        });


        $('#PR14').on('select2:select', function (e) {
    
            graphData({parametro: e.params.data.text ,filtro:"PR14"})
            document.getElementById('container7').classList.remove('no-display');

        });


        $("#PR23").select2({
            tags: true,
            placeholder: "Seleccione archivos",
            tokenSeparators: [',', ' ']
        });


        $('#PR23').on('select2:select', function (e) {
    
            graphData({parametro: e.params.data.text ,filtro:"PR23"})
            document.getElementById('container7').classList.remove('no-display');
        });



    });




    const filterBySearch = (a, b) => {
        const element = document.getElementById("container");
        const element1 = document.getElementById("container1");
        const element2 = document.getElementById("container2");
        const element3 = document.getElementById("container3");
        const element4 = document.getElementById("container4");
        const element5 = document.getElementById("container5");
        const element6 = document.getElementById("selectPr23");
        const element7 = document.getElementById("selectPr14");

        const contenedorPr = document.getElementById("PrOptions");
        const contenedorNoPr = document.getElementById("noPrOptions");

        if (a === 'Cortes') {
            element.classList.remove('no-display');
            element4.classList.add('no-display');
            element5.classList.add('no-display');
            element1.classList.remove('no-display');
            element2.classList.remove('no-display');
            element3.classList.remove('no-display');
            contenedorPr.classList.add('no-display');
            contenedorNoPr.classList.remove('no-display');
            filtro_global = 'Cortes'

        } else if (a === 'Reclamos') {
            element.classList.remove('no-display');
            element5.classList.add('no-display');
            element1.classList.remove('no-display');
            element2.classList.remove('no-display');
            element3.classList.remove('no-display');
            element4.classList.remove('no-display');
            contenedorPr.classList.add('no-display');
            contenedorNoPr.classList.remove('no-display');
            filtro_global = 'Reclamos'

        } else if (a === 'Resumen') {
            element.classList.remove('no-display');
            element1.classList.add('no-display');
            element2.classList.add('no-display');
            element3.classList.add('no-display');
            element4.classList.add('no-display');
            element5.classList.add('no-display');
            contenedorPr.classList.add('no-display');
            contenedorNoPr.classList.remove('no-display');
            filtro_global = "Resumen"

        } else if (a === 'Facturación') {
            (b.split('(')[1].split(')')[0] === 'Cliente') ? element1.classList.add('no-display') : element1.classList.remove('no-display');
            element.classList.remove('no-display');
            element1.classList.remove('no-display');
            element2.classList.add('no-display');
            element3.classList.add('no-display');
            element4.classList.add('no-display');
            element5.classList.add('no-display');
            contenedorPr.classList.add('no-display');
            contenedorPr.classList.add('no-display');
            contenedorNoPr.classList.remove('no-display');
            filtro_global = "Facturación"
        }

        if (a === 'Presiones' && b.split('(')[1].split(')')[0] === 'Localidad') {
            element.classList.remove('no-display');
            element4.classList.add('no-display');
            element5.classList.add('no-display');
            element1.classList.remove('no-display');
            element2.classList.add('no-display');
            element3.classList.add('no-display');
            contenedorPr.classList.add('no-display');
            contenedorNoPr.classList.remove('no-display');
            filtro_global = "Presiones"

        }



        if (a === 'PR14' && b.split('(')[1].split(')')[0] === 'Localidad') {


            element4.classList.add('no-display');
            element5.classList.add('no-display');
            element1.classList.add('no-display');
            element2.classList.add('no-display');
            document.getElementById('container7').classList.add('no-display');
            element3.classList.add('no-display');
            element.classList.add('no-display');
            element6.classList.add('no-display');
            element7.classList.add('no-display');
            contenedorPr.classList.remove('no-display');
            contenedorNoPr.classList.add('no-display');
            filtro_global = "PR14"


        }

        if (a === 'Turbiedad' && b.split('(')[1].split(')')[0] === 'Localidad') {
            
            element.classList.add('no-display');
            element4.classList.add('no-display');
            element5.classList.add('no-display');
            element1.classList.add('no-display');
            element2.classList.add('no-display');
            element3.classList.add('no-display');
            contenedorPr.classList.add('no-display');
            contenedorNoPr.classList.remove('no-display');
            filtro_global = "Turbiedad"


        }
        if ((a === 'Variables Operacionales' && b.split('(')[1].split(')')[0] === 'Localidad')
            || (a === 'Muestras Autocontrol' && b.split('(')[1].split(')')[0] === 'Localidad')
            || (a === 'Muestras Lodos' && b.split('(')[1].split(')')[0] === 'Localidad')) {

            element4.classList.add('no-display');
            document.getElementById('container7').classList.add('no-display');
            element6.classList.add('no-display');
            element7.classList.add('no-display');
            element5.classList.add('no-display');
            element1.classList.add('no-display');
            element2.classList.add('no-display');
            element3.classList.add('no-display');
            element.classList.add('no-display');
            contenedorPr.classList.remove('no-display');
            contenedorNoPr.classList.add('no-display');

        }


        if (a === 'Presiones' && b.split('(')[1].split(')')[0] !== 'Localidad') {
            element.classList.remove('no-display');
            element1.classList.add('no-display');
            element2.classList.add('no-display');
            element3.classList.add('no-display');
            element4.classList.add('no-display');
            element5.classList.add('no-display');

            alert('Para clientes y cuarteles no se pueden usar estos filtros')
        } else if (a === 'PR14' && b.split('(')[1].split(')')[0] !== 'Localidad') {

            element4.classList.add('no-display');
            element5.classList.add('no-display');
            element1.classList.add('no-display');
            element2.classList.add('no-display');
            element3.classList.add('no-display');
            contenedorNoPr.classList.add('no-display');
            element.classList.add('no-display');

            alert('Para clientes y cuarteles no se pueden usar estos filtros')
        } else if (a === 'Turbiedad' && b.split('(')[1].split(')')[0] !== 'Localidad') {
            element.classList.add('no-display');
            element4.classList.add('no-display');
            element5.classList.add('no-display');
            element1.classList.add('no-display');
            element2.classList.add('no-display');
            element3.classList.add('no-display');
            contenedorPr.classList.add('no-display');
            contenedorNoPr.classList.remove('no-display');

            alert('Para clientes y cuarteles no se pueden usar estos filtros')
        } else if ((a === 'Variables Operacionales' && b.split('(')[1].split(')')[0] !== 'Localidad')
            || (a === 'Muestras Autocontrol' && b.split('(')[1].split(')')[0] !== 'Localidad')
            || (a === 'Muestras Lodos' && b.split('(')[1].split(')')[0] !== 'Localidad')) {

            element4.classList.add('no-display');
            element5.classList.add('no-display');
            element1.classList.add('no-display');
            element2.classList.add('no-display');
            element3.classList.add('no-display');
            contenedorNoPr.classList.add('no-display');
            element.classList.add('no-display');

            alert('Para clientes y cuarteles no se pueden usar estos filtros')

        }


        else {
            search_selected(b, '', '', '', a);
            document.getElementById('plot-header-ind').innerHTML = a;
            document.getElementById('title').innerHTML = a;
            document.getElementById('subtitle').innerHTML = `Menú &gt; ${a}`;

        }



    }





    return (
        <React.Fragment>

            <div className="row header-style">
                <div className="col-2 menu-lateral-styles">
                    <MenuLateral
                        filterBySearch={filterBySearch}

                    />
                </div>



                <div className="col-10 container-general">
                    <Header changeMap={changeMap} />

                    <div className="row body-main">

                        <div className="col-6">

                            <div id="plot-card">
                                <Map dato={busqueda} />
                            </div>


                        </div>

                        <div className="col-6"><div id="plot-card">
                            <div className="plot-header">
                                <div id="plot-header-ind">Resumen</div>
                            </div>
                            <div className="plot-body" id="noPrOptions">





                                <div id="container"></div>
                                <div id="container1"></div>
                                <div id="container2"></div>
                                <div id="container3"></div>
                                <div id="container4"></div>
                                <div id="container5"></div>
                                <div id="container6"></div>
                            </div>


                            <div className="plot-body no-display" id="PrOptions">
                                <div>
                                    {/* 
                                    <DataTableExtensions {...tableData}>
                                        <DataTable
                                            columns={columns}
                                            data={data}
                                            noHeader
                                            defaultSortField="id"
                                            defaultSortAsc={true}
                                            pagination
                                            highlightOnHover
                                            conditionalRowStyles={conditionalRowStyles}
                                            dense
                                        />
                                    </DataTableExtensions> */}


                                    <table id="dataTable">

                                        <thead>
                                            <tr className="table-estilos" id='trFuncion'>



                                            </tr>
                                        </thead>
                                        <tbody id='bodyFuncion'>



                                        </tbody>



                                    </table>


                                    <div className=" col-12" id="selectPr14">
                                        <select className="form-select" name="states[]" id='PR14'>
                                            <option >Seleccione</option>
                                 
                                        </select>

                                    </div>
                                    <div className=" col-12" id="selectPr23">

                                    <select className="form-select" name="states[]" id='PR23'><option value="null">Seleccione</option>
                                    </select>
                                    </div>

                                    <div id="container7"></div>

                                    {/* <div className=" col-12" id="selectTurbiedad">

                                    <select className="form-select" name="states[]" id='Turbiedad'><option value="null">Seleccione</option>
                                    </select>
                                    </div> */}

                                    <div id="container7"></div>

                                </div>
                            </div>



                        </div></div>



                    </div>

                </div>




            </div>

        </React.Fragment>
    )
}

export default Main